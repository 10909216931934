<!-- Navigation -->
<fuse-vertical-navigation class="light bg-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigationData" [opened]="!isScreenSmall && navigationOpened"
    *ngIf="isPaidPlan">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="my-2 h-6 w-auto" src="assets/images/logo/logo-skillsme.png">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!-- <notifications [notifications]="data.notifications"></notifications> -->
                <user-menu [showAvatar]="false" [isPaidPlan]="isPaidPlan"></user-menu>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24 flex items-center justify-center group border rounded-full cursor-pointer"
                (click)="openUploadImage()">
                <img class="w-full h-full rounded-full block opacity-80 group-hover:opacity-20"
                    style="object-fit: cover;" *ngIf="business.avatar" [src]="baseImgUrl+business.avatar"
                    alt="User avatar">
                <mat-icon class="icon-size-12 absolute opacity-0 group-hover:opacity-100 text-black "
                    svgIcon="heroicons_outline:camera">
                </mat-icon>
                <input type="file" accept="image/*" #picuploader (input)="onInputImage($event)"
                    (change)="openFile($event)" style="display: none;">

                <mat-icon class="icon-size-24" *ngIf="!business.avatar" [svgIcon]="'heroicons_solid:user-circle'">
                </mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{business.name}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{business.email}}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <!-- <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.svg"> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto  min-width-dashboard sm:min-width-dashboard-web overflow-auto">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')" *ngIf="isPaidPlan">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <user-menu [showAvatar]="false" [isPaidPlan]="isPaidPlan" *ngIf="!isPaidPlan"></user-menu>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <!-- <fuse-fullscreen></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts [shortcuts]="data.shortcuts"></shortcuts>
            <messages [messages]="data.messages"></messages> -->
            <button mat-raised-button *ngIf="!isAdmin && isPaidPlan"
                (click)="openInvite();addUserAction('business_header_invite_click');" color="primary">
                Invite Talent
            </button>
            <button mat-raised-button *ngIf="!isAdmin && !isPaidPlan"
                (click)="copyInviteLink();addUserAction('business_header_copy_url_click');" color="primary">
                Copy Invitation Link
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>