import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    inactivityLoading: boolean = false;
    challengeExpiredDialogOpened: boolean = false;
    base = environment.profileGeneratebaseUrl;
    apiUrl = environment.apiUrl;
    baseUrl = environment.baseUrl;
    config: SocketIoConfig;

    // private socket: Socket;
    // private socket: any;

    // Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();

    // Observable string sources
    private emitDialogSource = new Subject<any>();
    // Observable string streams
    dialogEmitted$ = this.emitDialogSource.asObservable();

    signInSub: Subscription;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private socket: Socket,
        private _router: Router,
    ) {
        const token = localStorage.getItem('access_token');
        if (token) {
            this.formSocketIOConnectionWithAccessToken();
        }
        else if (this.signInSub && !this.signInSub['closed']) {
            this.signInSub.unsubscribe();
        }
    }
    private getHeaders() {
        let token = "Bearer " + localStorage.getItem("access_token");
        let headers = new HttpHeaders()
            .set("authorization", token)
            .set("Access-Control-Allow-Origin", this.base)
            .set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS")
            .set(
                "Access-Control-Allow-Headers",
                "Origin, Content-Type, X-Auth-Token"
            );
        // console.log("headers: ", headers);
        return headers;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    // Service message commands
    public emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    // Service dialog message commands
    public emitDialog(change: any) {
        this.emitDialogSource.next(change);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * connect to socket.io when log in
    //  *
    //  */

    public async formSocketIOConnectionWithAccessToken() {
        console.log('formSocketIOConnectionWithAccessToken is triggered');
        const token = localStorage.getItem("access_token");
        const timeout = 30000;
        console.log('this.socket: ', this.socket);
        console.log('this.baseUrl: ', this.baseUrl)
        this.config = {
            url: this.baseUrl,
            options: {
                reconnection: true,
                timeout: timeout,
                query: { token: token },
                upgrade: false
            }
        }
        this.socket = new Socket(this.config);
        await this.socket.connect();
        console.log('this.socket2: ', this.socket);
        this.socket.on('popup', function (msg) {
            console.log("hello: ", msg)
        });

        this.socket.on('connection', function () {
            console.log("client connected");
        });

        this.socket.on('connect_error', function (err) {
            console.error(`connect_error due to ${err.message}`);
        });

        this.socket.on('connect_timeout', function (err) {
            console.error(`connect_timeout due to ${err.message}`);
        });

        // this.signInSub = this.listen('sign in').subscribe(r => {
        //     console.log('sign in event detected');
        //     console.log('matching token');
        //     if (typeof r === 'object' && r['access_token'] !== localStorage.getItem('access_token')) {
        //         this.emitDialog({
        //             title: 'You have been logged out',
        //             message: 'Another user has signed in this account.',
        //             submitButtonType: 'primary',
        //             submitButtonText: 'Confirm'
        //         })
        //         this._router.navigate(['/sign-out']);
        //     }
        // })
    }

    // /**
    //  * connect to socket.io when come to profile link page as a user
    //  *
    //  */

    public async formSocketIOConnectionWithApplicationId(id) {
        console.log('formSocketIOConnectionWithApplicationId is triggered');
        console.log('id: ', id);
        const timeout = 5000;
    }

    // /**
    //  * disconnect from socket.io when log out
    //  *
    //  */

    public breakSocketIOConnection() {
        console.log('breakSocketIOConnection is triggered');
        if (this.socket && this.socket['ioSocket'] && this.socket['ioSocket']['connected']) {
            this.socket.disconnect();
        }
        if (this.signInSub && !this.signInSub['closed']) {
            this.signInSub.unsubscribe();
        }
    }

    listen(eventName: string) {
        console.log('this.socket: ', this.socket);
        return new Observable<any>((subscriber) => {
            if (this.socket && !this.socket['disconnected']) {
                this.socket.on(eventName, (data) => {
                    subscriber.next(data);
                });
            }
            else if (this.socket && this.socket['disconnected']) {
                this.socket.connect();
                console.log('this.socket2: ', this.socket);
                this.socket.on(eventName, (data) => {
                    subscriber.next(data);
                });
            }
        });
    }

    emit(eventName: string, data: any) {
        console.log("eventName: ", eventName);
        console.log("data: ", data);
        this.socket.emit(eventName, data, (r) => {
            console.log("emit successful");
            console.log("r: ", r);
        });
    }

    // /**
    //  * convert code to token github
    //  *
    //  */

    public getAccesstokenGithub(code: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `github/signin`, code, {
            headers: headers,
        });
    }

    // /**
    //  * get companies affected by disconnecting github
    //  *
    //  */
    public affectedCompaniesGithub(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `github/affected-companies`, {
            userId: userId
        }, {
            headers: headers,
        });
    }


    // /**
    //  * remove token github
    //  *
    //  */

    public removeAccesstokenGithub(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `github/disconnect`, userId, {
            headers: headers,
        });
    }


    // /**
    //  * remove token github just for this application
    //  *
    //  */

    public removeAccesstokenGithubSingle(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `github/disconnect/single`, userId, {
            headers: headers,
        });
    }

    // /**
    //  * convert code to token StackOverflow
    //  *
    //  */

    public getAccesstokenStackoverflow(code: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `stackoverflow/signin`, code, {
            headers: headers,
        });
    }

    // /**
    //  * get companies affected by disconnecting StackOverflow
    //  *
    //  */

    public affectedCompaniesStackoverflow(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `stackoverflow/affected-companies`,
            {
                userId: userId
            }, {
            headers: headers,
        });
    }

    // /**
    //  * remove token StackOverflow
    //  *
    //  */

    public removeAccesstokenStackoverflow(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `stackoverflow/disconnect`, userId, {
            headers: headers,
        });
    }

    // /**
    //  * remove token StackOverflow just for this application
    //  *
    //  */

    public removeAccesstokenStackoverflowSingle(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `stackoverflow/disconnect/single`, userId, {
            headers: headers,
        });
    }

    // /**
    //  * convert code to token gitlab
    //  *
    //  */

    public getAccesstokenGitlab(code: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `gitlab/signin`, code, {
            headers: headers,
        });
    }

    // /**
    //  * get companies affected by disconnecting Gitlab
    //  *
    //  */

    public affectedCompaniesGitlab(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `gitlab/affected-companies`,
            {
                userId: userId
            }, {
            headers: headers,
        });
    }


    // /**
    //  * remove token gitlab
    //  *
    //  */

    public removeAccesstokenGitlab(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `gitlab/disconnect`, userId, {
            headers: headers,
        });
    }


    // /**
    //  * remove token gitlab just for this application
    //  *
    //  */

    public removeAccesstokenGitlabSingle(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `gitlab/disconnect/single`, userId, {
            headers: headers,
        });
    }


    // /**
    //  * submit profile submission
    //  *
    //  */

    public submitProfile(folderId: any, userId: any, device) {
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `business/folder/invitation/apply`, {
            id: userId,
            uuid: folderId,
            device: device
        }, {
            headers: headers,
        });
    }


    public createNewUser() {
        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/create-new`, {
            headers: headers,
        });
    }

    // /**
    //  * create applicant email
    //  *
    //  */
    public createApplicantEmail(email: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/create-new/email`, {
            email: email,
        }, {
            headers: headers,
        });
    }

    // /**
    //  * create applicant email with uuid
    //  *
    //  */
    public createApplicantEmailWithFolderUrl(email: string, uuid: string, device: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/create-new/email/withfolderurl`, {
            email: email,
            uuid: uuid,
            device: device
        }, {
            headers: headers,
        });
    }

    // /**
    //  * create applicant email with uuid
    //  *
    //  */
    public createUserApplicationWithFolderUrlNoValidation(email: string, uuid: string, device: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/create-new/email/withfolderurl/v2`, {
            email: email,
            uuid: uuid,
            device: device
        }, {
            headers: headers,
        });
    }

    // /**
    //  * email verification code
    //  *
    //  */
    public applicantVerifyCode(userId: any, code: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/create-new/email/validate`, {
            id: userId,
            code: code,
        }, {
            headers: headers,
        });
    }

    // /**
    //  * update email
    //  *
    //  */
    public updateUserApplicationEmail(userId: any, email: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/create-new/email/update`, {
            id: userId,
            email: email,
        }, {
            headers: headers,
        });
    }

    // /**
    //  * check if user has validated
    //  *
    //  */
    public checkUserApplicationVerification(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/create-new/email/confirm/check/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * create and send verification code
    //  *
    //  */
    public createValidationCodeForUserApplication(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/create-new/email/validate/create`, {
            id: userId,
        }, {
            headers: headers,
        });
    }

    // /**
    //  * get github repos
    //  *
    //  */
    public getGithubPublicRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/public/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get github repos
    //  *
    //  */
    public getGithubPrivateRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/private/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get gitlab repos
    //  *
    //  */
    public getGitlabPublicRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/public/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get gitlab repos
    //  *
    //  */
    public getGitlabPrivateRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/private/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * toggle repo analyze
    //  *
    //  */
    public toggleRepoAnalyze(repoId: any, uId: any, active: any) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/repos/active`, {
            id: repoId,
            uid: uId,
            active: active,
        }, {
            headers: headers,
        });
    }


    // /**
    //  * get stackoverflow questions
    //  *
    //  */
    public getStackoverflowQuestions(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/stackoverflow/question/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get stackoverflow answers
    //  *
    //  */
    public getStackoverflowAnswers(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/stackoverflow/answer/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get linkedin profile
    //  *
    //  */
    public getLinkedinProfile(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/linkedin/profile/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * get linkedin experiences
    //  *
    //  */
    public getLinkedinExperiences(profileId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/linkedin/experience/` + profileId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get all linkedin experiences
    //  *
    //  */
    public getAllLinkedinExperiences(profileId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/linkedin/allexperience/` + profileId, {
            headers: headers,
        });
    }


    // /**
    //  * connect linkedin
    //  *
    //  */
    public connectLinkedin(linkedinUrl: any, userId: any) {

        const headers = this.getHeaders();
        // return this._httpClient.post<any>(this.base + `linkedin/sendurl`, {
        return this._httpClient.post<any>(this.base + `linkedin/send/url`, {
            url: linkedinUrl,
            userId: userId
        }, {
            headers: headers,
        });
    }


    // /**
    //  * check the current position of linkedin crawling request of this user
    //  *
    //  */
    public checkLinkedinCrawlingRequest(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `linkedin/query/` + userId, {
            headers: headers,
        });
    }


    // /**
    //  * get companies affected by disconnecting linkedin
    //  *
    //  */
    public affectedCompaniesLinkedin(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `linkedin/affected-companies`, {
            userId: userId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * disconnect linkedin
    //  *
    //  */
    public disconnectLinkedin(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `linkedin/disconnect`, {
            userId: userId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * disconnect linkedin just for this application
    //  *
    //  */
    public disconnectLinkedinSingle(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `linkedin/disconnect/single`, {
            userId: userId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * stackoverflow toggle data
    //  *
    //  */
    public getStackoverflowSettings(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/stackoverflow/settings/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * stackoverflow settings update
    //  *
    //  */
    public updateStackoverflowSettings(userId: any, question: any, answer: any) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/stackoverflow/settings`, {
            uid: userId,
            activeQuestion: question,
            activeAnswer: answer
        }, {
            headers: headers,
        });
    }

    // /**
    //  * update job settings
    //  *
    //  */
    public updateLinkedinJobSettings(userId: any, jobId: any, active: any) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `linkedin/experience/activation`, {
            uid: userId,
            id: jobId,
            activation: active
        }, {
            headers: headers,
        });
    }

    // /**
    //  * get full skill list
    //  *
    //  */
    public getSkillSet() {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `topiclist/active`, {
            headers: headers,
        });
    }

    // /**
    //  * add skill to job
    //  *
    //  */
    public addSkillToJob(userId: any, jobId: any, sId: any) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/linkedin/experience/skill/add`, {
            aid: userId,
            id: jobId,
            sid: sId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * remove skill to job
    //  *
    //  */
    public removeSkillFromJob(userId: any, jobId: any, sId: any) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/linkedin/experience/skill/delete`, {
            aid: userId,
            id: jobId,
            sid: sId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * set skill for job
    //  *
    //  */
    public setSkillForJob(userId: any, jobId: any, skills: number[]) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/linkedin/experience/skill/set`, {
            aid: userId,
            id: jobId,
            skills: skills
        }, {
            headers: headers,
        });
    }

    // /**
    //  * get company banner
    //  *
    //  */
    public getCompanyLogoBannerByUrl(url) {
        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `business/logo_banner/getbyurl/` + url, {
            headers: headers,
        });
    }

    // /**
    //  * set dev to job
    //  *
    //  */
    public setJobToDev(jobId: any, status: boolean) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/linkedin/experience/dev/set`, {
            id: jobId,
            dev: status
        }, {
            headers: headers,
        });
    }

    // /**
    //  * reset dev status to all job experiences by application id
    //  *
    //  */
    public resetJobToDev(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/detail/linkedin/experience/dev/reset`, {
            id: userId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * check profile general info
    //  *
    //  */
    public getProfileGeneral(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/general/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * check profile completeness
    //  *
    //  */
    public checkProfileCompleteness(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/general/checkcompleteness/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * check user confirmation
    //  *
    //  */
    public checkUserConfirmation(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/setting/confirmation/` + userId, {
            headers: headers,
        });
    }


    // /**
    //  * update user confirmation
    //  *
    //  */
    public updateUserConfirmation(userId: any, status: boolean) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.base + `applicant/setting/confirmation`, {
            id: userId,
            value: status
        }, {
            headers: headers,
        });
    }


    // /**
    //  * refetch github
    //  *
    //  */
    public refetchGithub(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/github/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * refetch github
    //  *
    //  */
    public refetchGitlab(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/gitlab/` + userId, {
            headers: headers,
        });
    }


    // /**
    //  * refetch github
    //  *
    //  */
    public refetchlinkedin(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/linkedin/` + userId, {
            headers: headers,
        });
    }
    // /**
    //  * refetch github
    //  *
    //  */
    public refetchStackoverflow(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/stackoverflow/` + userId, {
            headers: headers,
        });
    }


    // /**
    //  * get github repos
    //  *
    //  */
    public getGithubMobilePublicRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/mobile/public/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get github repos
    //  *
    //  */
    public getGithubMobilePrivateRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/mobile/private/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get gitlab repos
    //  *
    //  */
    public getGitlabMobilePublicRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/mobile/public/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get gitlab repos
    //  *
    //  */
    public getGitlabMobilePrivateRepos(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/mobile/private/` + userId + `/` + page, {
            headers: headers,
        });
    }


    // /**
    //  * get affected companies
    //  *
    //  */
    public getAffectedCompanies(userId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/general/affected-company/` + userId, {
            headers: headers,
        });
    }

    // /**
    //  * disconnect profile from company
    //  *
    //  */
    public disconnectProfileFromCompany(userId: any, reason: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/detail/general/disconnect/company`, {
            url: userId,
            reason: reason
        }, {
            headers: headers,
        });
    }

    // /**
    //  * disconnect profile from company
    //  *
    //  */
    public disconnectProfileFromSkillsme(url: any, reason: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/detail/general/disconnect/skillsme`, {
            url: url,
            reason: reason
        }, {
            headers: headers,
        });
    }


    // /**
    //  * get profile info from delete url
    //  *
    //  */
    public getUserInfoFromDeleteUrl(url: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/general/disconnect/affected-company/` + url, {
            headers: headers,
        });
    }

    // /**
    //  * get profile info from disconnect url
    //  *
    //  */
    public getUserInfoFromDisconnectUrl(url: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/general/disconnect/affected-company-single/` + url, {
            headers: headers,
        });
    }

    public emailUnsubscriptionApplicant(uuid) {
        console.log("emailUnsubscription is triggered");
        console.log("uuid: ", uuid);
        const url = this.base + "applicant/setting/subscription/deactivate/byurl/" + uuid;
        return this._httpClient.get<any>(url);
    }

    public postEmailUnsubscriptionReasonApplicant(req) {
        console.log("postEmailUnsubscriptionReasonApplicant is triggered");
        console.log("req: ", req);
        const url = this.base + "applicant/setting/subscription/deactivate/reason";
        return this._httpClient.post<any>(url, req);
    }

    // /**
    //  * check tour record
    //  *
    //  */
    public checkTourRecord(pageName) {
        console.log("checkTourRecord is triggered");
        console.log("pageName: ", pageName);
        const headers = this.getHeaders();
        const url = this.apiUrl + "user/tourrecord/" + pageName;
        return this._httpClient.get<any>(url, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch userinfo
    //  *
    //  */
    public getRefetchUserInfo(url: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/getinfo/byurl/` + url, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch linkedin jobs
    //  *
    //  */
    public getRefetchLinkedinJobs(id: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/linkedin/allexperience/byfetchrecordid/` + id, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch github public for web
    //  *
    //  */
    public getRefetchGithubPublicRepoForWeb(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/public/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch github public for mobile
    //  *
    //  */
    public getRefetchGithubPublicRepoForMobile(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/mobile/public/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }


    // /**
    //  * get refetch github private for web
    //  *
    //  */
    public getRefetchGithubPrivateRepoForWeb(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/private/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch github private for mobile
    //  *
    //  */
    public getRefetchGithubPrivateRepoForMobile(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/github/repos/mobile/private/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }




    // /**
    //  * get refetch gitlab public for web
    //  *
    //  */
    public getRefetchGitlabPublicRepoForWeb(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/public/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch gitlab public for mobile
    //  *
    //  */
    public getRefetchGitlabPublicRepoForMobile(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/mobile/public/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }


    // /**
    //  * get refetch gitlab private for web
    //  *
    //  */
    public getRefetchGitlabPrivateRepoForWeb(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/private/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch gitlab private for mobile
    //  *
    //  */
    public getRefetchGitlabPrivateRepoForMobile(id: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/gitlab/repos/mobile/private/byfetchrecordid/` + id + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get stackoverflow questions
    //  *
    //  */
    public getRefetchStackoverflowQuestions(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/stackoverflow/question/` + userId + `/` + page, {
            headers: headers,
        });
    }

    // /**
    //  * get stackoverflow answers
    //  *
    //  */
    public getRefetchStackoverflowAnswers(userId: any, page: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/detail/stackoverflow/answer/` + userId + `/` + page, {
            headers: headers,
        });
    }


    // /**
    //  * submit refetch userinfo
    //  *
    //  */
    public submitRefetchInfoByUrl(url: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/submit/byurl/` + url, {
            headers: headers,
        });
    }


    // /**
    //  * reject refetch userinfo
    //  *
    //  */
    public rejectRefetchInfoByUrl(url: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/refetch/deactivate/byurl/` + url, {
            headers: headers,
        });
    }


    // /**
    //  * get refetch github record
    //  *
    //  */
    public getRefetchGithubRecord(recordId: any, externalId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/fetchrecord/analyse/repo/github/` + recordId + '/' + externalId, {
            headers: headers,
        });
    }


    // /**
    //  * get refetch gitlab record
    //  *
    //  */
    public getRefetchGitlabRecord(recordId: any, externalId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/fetchrecord/analyse/repo/gitlab/` + recordId + '/' + externalId, {
            headers: headers,
        });
    }


    // /**
    //  * get refetch linkedin record
    //  *
    //  */
    public getRefetchLinkedinRecord(recordId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/fetchrecord/analyse/experience/linkedin/` + recordId, {
            headers: headers,
        });
    }

    // /**
    //  * submit refetch linkedin record
    //  *
    //  */
    public submitRefetchLinkedinRecord(exp: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/fetchrecord/analyse/experience/linkedin`, exp, {
            headers: headers,
        });
    }

    // /**
    //  * submit refetch gitlab record
    //  *
    //  */
    public submitRefetchGitlabRecord(exp: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/fetchrecord/analyse/repo/gitlab`, exp, {
            headers: headers,
        });
    }

    // /**
    //  * submit refetch github record
    //  *
    //  */
    public submitRefetchGithubRecord(exp: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/fetchrecord/analyse/repo/github`, exp, {
            headers: headers,
        });
    }


    public uploadResumeByApplicant(id: number, file) {
        console.log("uploadResumeByApplicant is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/cv/upload/` + id, file, {
            headers: headers,
        });
    }

    public uploadCoverletterByApplicant(id: number, file) {
        console.log("uploadCoverletterByApplicant is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/coverletter/upload/` + id, file, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch stackoverflow  by emailrecord id
    //  *
    //  */
    public getRefetchStackoverflowByEmailRecordId(emailRecordId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `stackoverflow/updateemailid/` + emailRecordId, {
            headers: headers,
        });
    }

    // /**
    //  * get refetch linkedin  by emailrecord id
    //  *
    //  */
    public getRefetchLinkedinflowByEmailRecordId(emailRecordId: any) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `linkedin/updateemailid/` + emailRecordId, {
            headers: headers,
        });
    }


    // /**
    //  * submiit refetch stackoverflow  by emailrecord id
    //  *
    //  */
    public submitRefetchStackoverflowByEmailRecordId(data: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `stackoverflow/updateemailid`, data, {
            headers: headers,
        });
    }

    // /**
    //  * submiit refetch linkedin  by emailrecord id
    //  *
    //  */
    public submitRefetchLinkedinByEmailRecordId(data: any) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `linkedin/updateemailid`, data, {

            headers: headers,
        });
    }

    // /**
    //  * get full name by id
    //  *
    //  */
    public getNameByApplicationId(id: number) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/fullname/` + id, {
            headers: headers,
        });
    }

    // /**
    //  * add full name by id
    //  *
    //  */
    public addNameByApplicationId(id: number, firstName: string, lastName: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/fullname`,
            {
                id: id,
                firstName: firstName,
                lastName: lastName,
            },
            {
                headers: headers,
            });
    }


    // /**
    //  * submit linkedin profile
    //  *
    //  */
    public submitLinkedProfile(result) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `linkedin/send/result`,
            result,
            {
                headers: headers,
            });
    }


    // /**
    //  * post a code snippet as an applicant
    //  *
    //  */
    public postCodeSnippetByApplicationId(req) {
        console.log('postCodeSnippetByApplicationId is triggered');
        console.log('req: ', req);
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/code-snippet/post`,
            req, {
            headers: headers,
        });
    }


    // /**
    //  * delete a code snippet as an applicant
    //  *
    //  */
    public deleteCodeSnippetById(result) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/code-snippet/delete`,
            result,
            {
                headers: headers,
            });
    }


    // /**
    //  * get a list of code snippets of an applicant
    //  *
    //  */
    public getCodeSnippetByApplicationIdAndPage(result) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/code-snippet/get/bypage`,
            result,
            {
                headers: headers,
            });
    }


    // /**
    //  * get the content of a certain code snippet as an applicant
    //  *
    //  */
    public getCodeSnippetContentByItemId(id) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/code-snippet/get/detail/` + id,
            {
                headers: headers,
            });
    }

    // /**
    //  * post code challenge registration form
    //  *
    //  */
    public postRegisterCodingChallenge(data) {
        console.log('postRegisterCodingChallenge is triggered');
        console.log('req: ', data);
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/codingchallenge/apply`,
            data, {
            headers: headers,
        });
    }

    // /**
    //  * get the code challenge form data of an applicant
    //  *
    //  */
    public loadCodingChallengeFormContent(id) {
        console.log('loadCodingChallengeFormContent is triggered');
        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/codingchallenge/load/form/` + id,
            {
                headers: headers,
            });
    }

    // /**
    //  * get the code challenge content of an applicant
    //  *
    //  */
    public getCodingChallengeByApplicantId(id) {
        console.log('getCodingChallengeByApplicantId is triggered');
        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/codingchallenge/load/challenge/` + id,
            {
                headers: headers,
            });
    }

    // /**
    //  * upload file as submission for code challenge
    //  *
    //  */
    public uploadCodingChallengeByApplicant(id: number, file) {
        console.log("uploadCodingChallengeByApplicant is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/codingchallenge/upload/` + id, file, {
            headers: headers,
        });
    }

    // /**
    //  * upload file as submission for code challenge
    //  *
    //  */
    public removeFileFromApplicationById(id: number, removeCV: boolean, removeCoverLetter: boolean) {
        console.log("removeFileFromApplicationById is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/removefile`, {
            aid: id,
            removeCV: removeCV,
            removeCoverLetter: removeCoverLetter
        }, {
            headers: headers,
        });
    }

    // /**
    //  * confirm a code challenge relationship
    //  *
    //  */
    public confirmCodingChallengeRelationship(id: number, endId: number, startNow: boolean) {
        console.log("confirmCodingChallengeRelationship is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/codingchallenge/confirm`, {
            aid: id,
            endId: endId,
            startNow: startNow
        }, {
            headers: headers,
        });
    }

    // /**
    //  * cancel schedule and start the challenge now
    //  *
    //  */
    public startNowForPreviouslyScheduledChallenge(id: number) {
        console.log("startNowForPreviouslyScheduledChallenge is triggered");
        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.base + `applicant/codingchallenge/startnow/` + id, {
            headers: headers,
        });
    }

    // /**
    //  * cancel an in process code challenge
    //  *
    //  */
    public cancelCodingChallengeByApplicantId(ccrid, id) {
        console.log('cancelCodingChallengeByApplicantId is triggered');
        console.log('ccrid: ', ccrid);
        console.log('id: ', id);
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/codingchallenge/cancel`,
            {
                ccrid: ccrid,
                id: id
            }, {
            headers: headers,
        });
    }

    // /**
    //  * adding a user action to the database
    //  *
    //  */
    public addUserAction(actionData) {
        console.log("addUserAction is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.base + `applicant/useraction/add`, actionData, {
            headers: headers,
        });
    }


}
