import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { StringDecoder } from 'string_decoder';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {

    // Observable string sources
    private emitFolderIdSource = new Subject<any>();
    // Observable string streams
    folderIdEmitted$ = this.emitFolderIdSource.asObservable();

    // Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();

    // Observable string sources
    private emitDialogSource = new Subject<any>();
    // Observable string streams
    dialogEmitted$ = this.emitDialogSource.asObservable();

    apiUrl = environment.apiUrl;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // Service message commands
    public emitFolderId(change: any) {
        this.emitFolderIdSource.next(change);
    }

    // Service message commands
    public emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    // Service message commands
    public emitDialog(change: any) {
        this.emitDialogSource.next(change);
    }

    private getHeaders() {
        let token = "Bearer " + localStorage.getItem("access_token");
        let headers = new HttpHeaders()
            .set("authorization", token)
            .set("Access-Control-Allow-Origin", this.apiUrl)
            .set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS")
            .set(
                "Access-Control-Allow-Headers",
                "Origin, Content-Type, X-Auth-Token"
            );
        // console.log("headers: ", headers);
        return headers;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * get my folders
    //  *
    //  */

    public getAllMyFolders() {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/list/all`, {
        }, {
            headers: headers,
        });
    }

    // /**
    //  * create new folders
    //  *
    //  */

    public createNewFolder(name: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/create`, {
            name: name
        }, {
            headers: headers,
        });
    }

    // /**
    //  * delete  folders
    //  *
    //  */

    public deleteFolder(id: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/delete`, {
            id: id
        }, {
            headers: headers,
        });
    }

    // /**
    //  * rename  folders
    //  *
    //  */

    public renameFolder(id: string, name: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/rename`, {
            id: id,
            name: name
        }, {
            headers: headers,
        });
    }

    // /**
    //  * create invitation
    //  *
    //  */

    public sendTestInvitationEmail(fromEmail: string, fid: number, url: string, subject: string, message: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/invitation/testemail`, {
            fromEmail: fromEmail,
            fid: fid,
            url: url,
            subject: subject,
            message: message
        }, {
            headers: headers,
        });
    }

    public createInvitation(folderId: string, email: string, fname: string, lname: string, subject: string, message: string, fromEmail: string) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/invitation/create/single`, {
            id: folderId,
            email: email,
            firstName: fname,
            lastName: lname,
            subject: subject,
            message: message,
            fromEmail: fromEmail
        }, {
            headers: headers,
        });
    }


    // /**
    //  * move user to different folder
    //  *
    //  */

    public moveUserToFolder(userId: string, oldFid: string, newFid: string, isInvitation: boolean) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.apiUrl + `profile-gen/business/folder/talent/move`, {
            id: userId,
            old_fid: oldFid,
            new_fid: newFid,
            is_invitation: isInvitation
        }, {
            headers: headers,
        });
    }


    // /**
    //  * move user to different folder
    //  *
    //  */

    public duplicateUserToFolder(userId: string, fid: StringDecoder) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/talent/duplicate`, {
            id: userId,
            fid: fid
        }, {
            headers: headers,
        });
    }


    // /**
    //  * remove user from folder
    //  *
    //  */

    public removeUserFromFolder(userId: string, fid: string, isInvitation: boolean, deleteAll: boolean) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.apiUrl + `profile-gen/business/folder/talent/remove`, {
            id: userId,
            fid: fid,
            is_invitation: isInvitation,
            delete_all: deleteAll
        }, {
            headers: headers,
        });
    }

    // /**
    //  * get all skills 
    //  *
    //  */
    public getSkillSet() {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `topiclist/active`, {
            headers: headers,
        });
    }

    // /**
    //  * get skill list by user id
    //  *
    //  */
    public getSkillSetByUserId() {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `topiclist/active/userid`, {
            headers: headers,
        });
    }

    // /**
    //  * get skill list by end id
    //  *
    //  */
    public getSkillSetByEndId(endId) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `topiclist/active/codingchallenge/byendid/` + endId, {
            headers: headers,
        });
    }


    public getFolderStats(fid) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/talent/stats`, {
            fid: fid
        }, {
            headers: headers,
        });
    }

    public getAllFilterByFolderId(fid) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/filter/get`, {
            fid: fid
        }, {
            headers: headers,
        });
    }

    public addFolderFilter(fid, categoryId, itemId) {
        console.log('addFolderFilter is triggered');
        console.log('fid: ', fid);
        console.log('categoryId: ', categoryId);
        console.log('itemId: ', itemId);
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/filter/add`, {
            fid: fid,
            categoryId: categoryId,
            itemId: itemId,
        }, {
            headers: headers,
        });
    }

    public addFolderFilterSkillLevel(fid, categoryId, itemId, level) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/filter/add`, {
            fid: fid,
            categoryId: categoryId,
            itemId: itemId,
            levelId: level
        }, {
            headers: headers,
        });
    }

    public addFolderFilterGroup(fid, filters) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/filter/add/group`, {
            fid: fid,
            filters: filters
        }, {
            headers: headers,
        });
    }

    public deleteFolderFilter(fid, filterId) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/filter/delete`, {
            fid: fid,
            filterId: filterId,
        }, {
            headers: headers,
        });
    }

    public getTalentWithFilters(fid, page, inProgress, name) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/talent/list`, {
            fid: fid,
            page: page,
            inProgress: inProgress,
            name: name
        }, {
            headers: headers,
        });
    }


    // /**
    //  * get all coutries 
    //  *
    //  */
    public getCountryList() {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `profile-gen/general/countrylist`, {
            headers: headers,
        });
    }

    public deleteAllFolderFilter(fid) {
        console.log('deleteAllFolderFilter is triggered');
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/filter/deleteall`, {
            fid: fid,
        }, {
            headers: headers,
        });
    }

    public getTalentByName(fid, page, name) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/talent/byname`, {
            fid: fid,
            page: page,
            name: name,
        }, {
            headers: headers,
        });
    }

    public getCompanyLogoBannerByUrl(url) {
        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `profile-gen/business/logo_banner/getbyurl/` + url, {
            headers: headers,
        });
    }

    public postCustomizableContent(content_id, title, content, button_text) {
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/customizablecontent/post`, {
            content_id: content_id, title: title, content: content, button_text: button_text
        }, {
            headers: headers,
        });
    }

    public deleteCustomizableContent(content_id) {
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/customizablecontent/delete`, {
            content_id: content_id
        }, {
            headers: headers,
        });
    }

    public getAllCustomizableContentByUserId() {
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/customizablecontent/get/all`, {}, {
            headers: headers,
        });
    }

    public getCustomizableContentByFolderUrlAndContentId(url, content_id) {
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/customizablecontent/get/byfolderandid`, {
            url: url, content_id: content_id
        }, {
            headers: headers,
        });
    }

    public uploadPhoto(id: number, values: any) {
        console.log("uploadPhoto is triggered");
        console.log("values: ", values);
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `user/` + id + `/photo`, values, {
            headers: headers,
        });
    }

    public uploadPhotoV2(id: number, values: any) {
        console.log("uploadPhotoV2 is triggered");
        console.log("values: ", values);
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `user/photo/v2/` + id, values, {
            headers: headers,
        });
    }

    public emailUnsubscription(uuid) {
        console.log("emailUnsubscription is triggered");
        console.log("uuid: ", uuid);
        const url = this.apiUrl + "email/unsubscription/" + uuid;
        return this._httpClient.get<any>(url);
    }

    public putEmailUnsubscriptionReason(req) {
        console.log("putEmailUnsubscriptionReason is triggered");
        console.log("req: ", req);
        const url = this.apiUrl + "email/unsubscription";
        return this._httpClient.put<any>(url, req);
    }

    public changePassword(password) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.apiUrl + `user/updatepassword`, {
            password: password,
        }, {
            headers: headers,
        });
    }

    public checkOldPassword(password) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.apiUrl + `user/confirmpassword`, {
            password: password,
        }, {
            headers: headers,
        });
    }

    public uploadBannerPhoto(id: number, file) {
        console.log("uploadBannerPhoto is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/logo_banner/upload/` + id, file, {
            headers: headers,
        });
    }

    public uploadBannerPhotoV2(id: number, file) {
        console.log("uploadBannerPhotoV2 is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/logo_banner/v2/upload/` + id, file, {
            headers: headers,
        });
    }

    public getBusinessBanner(userId) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `profile-gen/business/logo_banner/getbyuserid/` + userId, {
            headers: headers,
        });
    }

    public updateBusinessName(name) {

        const headers = this.getHeaders();
        return this._httpClient.put<any>(this.apiUrl + `business/name/edit`, {
            name: name,
        }, {
            headers: headers,
        });
    }

    // /**
    //  * add or adjust a skill of user application by business
    //  *
    //  */
    public assignSkillToUserApplicationByBusiness(req) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/applicants/skill/add`, req, {
            headers: headers,
        });
    }

    // /**
    //  * delete a skill of user application by business
    //  *
    //  */
    public deleteSkillFromUserApplicationByBusiness(req) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/applicants/skill/delete`, req, {
            headers: headers,
        });
    }


    // /**
    //  * check the account is dns supported
    //  *
    //  */
    public checkDNSVerifiedByBusiness(email) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `my/business/emailsupport`, {
            email: email
        }, {
            headers: headers,
        });
    }

    public getCountryListByFolderId(folderId) {
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/countrylist/all`, {
            fid: folderId
        }, {
            headers: headers,
        });
    }

    // /**
    //  * update folder order
    //  *
    //  */

    public updateFolderOrder(folder) {

        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/folder/order/update`, {
            folder: folder
        }, {
            headers: headers,
        });
    }

    // /**
    //  * get a list of coding challenge by application id
    //  *
    //  */
    public getCodingChallengeListByApplicationId(applicationId) {

        const headers = this.getHeaders();
        return this._httpClient.get<any>(this.apiUrl + `profile-gen/business/applicants/codingchallenge/list/get/` + applicationId, {
            headers: headers,
        });
    }

    // /**
    //  * adding a user action to the database
    //  *
    //  */
    public addUserAction(actionData) {
        console.log("business addUserAction is triggered");
        const headers = this.getHeaders();
        return this._httpClient.post<any>(this.apiUrl + `profile-gen/business/useraction/add`, actionData, {
            headers: headers,
        });
    }

}

