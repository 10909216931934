<div class="flex flex-col w-120 -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 mt-5 ">
        <div class="text-3xl font-medium">Edit business logo</div>
        <button mat-icon-button class="bg-gray-300" (click)="saveAndClose()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <form class="flex flex-col flex-auto  p-6 sm:p-8 overflow-y-auto" [formGroup]="composeForm" autocomplete="off"
        *ngIf="loadFinish">

        <!-- <span class="font-medium text-base mb-2">Business logo</span> -->
        <div class="">
            <div class="bg-white mb-6 border-4 border-dashed w-max" *ngIf="!currentBannerAvailable"
                [ngClass]="{'hidden':!fileUploaded}">
                <div class="relative">

                    <span
                        class="absolute -right-3 -top-4  w-6 h-6 ml-auto flex items-center justify-center  rounded-full bg-warn cursor-pointer"
                        (click)="clearImage()" *ngIf="fileUploaded">
                        <mat-icon class="text-white icon-size-6" [svgIcon]="'mat_outline:close'">
                        </mat-icon>
                    </span>
                </div>
                <img class="" style="width:400px; height:80px; object-fit: cover;" id="uploadedImage" src="#"
                    alt="your image" />
            </div>
            <div class="bg-white mb-6 border-4 border-dashed w-max" *ngIf="currentBannerAvailable">
                <div class="relative">

                    <span
                        class="absolute -right-3 -top-4  w-6 h-6 ml-auto flex items-center justify-center  rounded-full bg-warn cursor-pointer"
                        (click)="clearImage()">
                        <mat-icon class="text-white icon-size-6" [svgIcon]="'mat_outline:close'">
                        </mat-icon>
                    </span>
                </div>
                <img class="" style="width:400px; height:80px; object-fit: cover;" [src]="currentBannerUrl"
                    alt="your image" />
            </div>
        </div>
        <div class="bg-white mb-6 border-4 border-dashed" *ngIf="!fileUploaded && !currentBannerAvailable">

            <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" accept="image/*"
                dropZoneClassName="flex w-full h-56   items-center justify-center ">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <!-- <button type="button" (click)="openFileSelector()">Browse Files</button> -->
                    <div class=" flex text-primary items-center flex flex-col">
                        <mat-icon class="icon-size-7 text-primary" svgIcon="mat_outline:image"></mat-icon>
                        <span class="text-xl font-semibold cursor-pointer select-none hover:underline"
                            (click)="openImageUpload()">
                            Drop or upload banner image
                        </span>
                        <div style="width: 100%;
                        text-align: center;">Ideal size: 400 x 80 </div>
                    </div>
                </ng-template>
            </ngx-file-drop>

            <input type="file" accept="image/*" #imageuploader (change)="openFile($event)" style="display: none;">
        </div>







        <!-- Actions -->
        <div class="flex flex-row sm:items-center justify-between mt-4 sm:mt-6">


            <div class="flex items-center mt-4 sm:mt-0 ml-auto">
                <!-- Send -->
                <button class="mr-4" mat-flat-button (click)="saveAndClose()">
                    Cancel
                </button>
                <button class="order-first sm:order-last" mat-flat-button [color]="'primary'"
                    [disabled]="checkDisable()" (click)="saveChanges()">
                    Save
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>