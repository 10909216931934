import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { UserService } from 'app/core/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    encapsulation: ViewEncapsulation.None
})
export class changePasswordComponent implements OnInit {
    resetForm: FormGroup;

    oldPasswordMatch: any = false;

    loader: boolean = false;
    userId: any;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;

    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<changePasswordComponent>,
        private _formBuilder: FormBuilder,
        private _businessService: BusinessService,
        private _userService: UserService,
        private _matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        this._userService.user$.subscribe((f) => {
            console.log('user', f);
            this.userId = f.id;
        });
        // Create the form
        this.resetForm = this._formBuilder.group({
            oldPassword: ['', [Validators.required, Validators.maxLength(50)]],
            newPassword: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(6)]],

        });



    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------






    /**
     * link the linkedin
     */
    close(): void {
        this.addUserAction('business_change_password_popup_close_click');
        this.matDialogRef.close(false);
    }


    /**
   * create new folder
   */
    changePassword() {
        // this.resetForm.controls['oldPassword'].reset();
        if (!this.loader) {
            this.loader = true;
            this._businessService.checkOldPassword(this.resetForm.value.oldPassword).subscribe((f) => {
                console.log('changes', f);
                this._businessService.changePassword(this.resetForm.value.newPassword).subscribe((k) => {
                    this._matSnackBar.open("Password has been changed!", 'Close', {
                        duration: 4000
                    });
                    this.matDialogRef.close({
                        submit: true
                    });
                }, (err) => {
                    this.loader = false;
                    console.error('changePassword failed, error: ', err);
                    this._matSnackBar.open("An error has occured. ", 'Close', {
                        duration: 4000
                    });
                })

            }, (err) => {
                this.loader = false;
                console.error('checkOldPassword failed, error: ', err);
                this.resetForm.controls['oldPassword'].setErrors({ 'incorrect': true });
            });
        }
    }



    submitForm() {
        this.addUserAction('business_change_password_popup_submit_click');
        console.log('this.resetForm.valid', this.resetForm.valid)
        if (this.resetForm.valid) {
            this.changePassword();
        }
        else {
            this._matSnackBar.open('Please enter a new password to submit.', 'Close', {
                duration: 4000
            });
        }
    }

    onBlur() {
        console.log('old password not focus')
        this._businessService.checkOldPassword(this.resetForm.value.oldPassword).subscribe((f) => {
            console.log('changes', f);
            this.oldPasswordMatch = true;
        }, (err) => {
            this.oldPasswordMatch = false;
            this.resetForm.controls['oldPassword'].setErrors({ 'incorrect': true });
        });
    }
    onFocus() {
        // this.resetForm.controls['oldPassword'].setErrors(null);
    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.userId,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }


}
