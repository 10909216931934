import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserMenuComponent } from 'app/layout/common/user-menu/user-menu.component';
import { SharedModule } from 'app/shared/shared.module';
import { changePasswordComponent } from 'app/layout/common/change-password/change-password.component';
import { BusinessLogoComponent } from 'app/layout/common/business-logo/business-logo.component';
import { BusinessNameEditComponent } from 'app/layout/common/business-name-edit/business-name-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        UserMenuComponent,
        changePasswordComponent,
        BusinessLogoComponent,
        BusinessNameEditComponent
    ],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSnackBarModule,
        MatDialogModule,
        NgxFileDropModule,
        MatProgressSpinnerModule

    ],
    exports: [
        UserMenuComponent
    ]
})
export class UserMenuModule {
}
