import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { GeneralService } from '../services/general.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    baseUrl = environment.apiUrl;
    timer = null;
    token = new BehaviorSubject(this.accessToken);
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _generalService: GeneralService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        if (token && token !== 'undefined') {
            localStorage.setItem('access_token', token);
            this._generalService.formSocketIOConnectionWithAccessToken();
            this.token.next(token);
            // this._generalService.formSocketIOConnectionWithAccessToken();
        }
    }

    get accessToken(): string {
        return localStorage.getItem('access_token') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(this.baseUrl + 'auth/reset-password', {
            email: email
        });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string, uuid: string): Observable<any> {
        return this._httpClient.put(this.baseUrl + 'auth/reset-password', {
            uuid: uuid,
            password: password
        });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string, rememberme: boolean }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(this.baseUrl + 'auth/login', credentials).pipe(
            switchMap((response: any) => {

                console.log('response: ', response);

                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                let user = {
                    id: response.user.id,
                    name: response.user.business_name,
                    email: response.user.email,
                    avatar: response.user.photo_url,
                    status: 'online',
                    role: response.user.role,
                    plan: response.user.business_plan_operating
                }
                this._userService.user = user;


                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        console.log('working refresh')
        // Renew token
        this._authenticated = true;
        this._userService.user = JSON.parse(localStorage.getItem('tri-user'));
        return of(true)
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        console.log('signOut is triggered');
        // Remove the access token from the local storage
        this.clearStopTimer();
        localStorage.removeItem('access_token');
        localStorage.removeItem('tri-user');
        this._generalService.breakSocketIOConnection();

        this._userService.folder = [];
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string, email: string, password: string, company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string, password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Add a timer to signout
     */
    async startExpireTimer(expireDate) {
        var startDate = new Date();
        var endDate = new Date(expireDate);
        var remainsec = (endDate.getTime() - startDate.getTime());
        // var remainsec = 10000;
        // console.log('expire seconds', Math.round(remainsec))
        const seconds = Math.round(remainsec);
        this.timer = null;

        return new Promise((resolve) => {
            this.timer = setTimeout((k) => {
                resolve(true)
            }, seconds);
        });
    }

    /**
     * Stopping a timer when user logout
     */
    clearStopTimer() {
        console.log('exp timer: ', this.timer);
        clearTimeout(this.timer);
    }
}
