<div class="flex flex-col min-w-100 max-w-160 h-auto -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-1 items-center justify-between h-16 px-6  mt-5">
        <div>

            <div class="text-2xl font-medium ">You are leaving the invitation send process</div>
        </div>
        <button mat-icon-button class="bg-gray-300" (click)="close()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <div class="bg-gray-50 flex flex-col flex-auto p-6  overflow-y-auto">

        <p class="mb-6 font-medium">
            If you leave without sending, all changes made in invitation content will be lost. 
        </p>




        <!-- Actions -->
        <div class="flex flex-row ml-auto items-center mt-4">

            <button mat-button class="ml-auto sm:mt-0 sm:ml-0 mr-3 bg-gray-400 text-white" mat-flat-button
                (click)="close()">
                Cancel
            </button>
            <button class="ml-auto sm:mt-0 sm:ml-0" mat-flat-button [color]="'primary'" (click)="discard()">
                {{data.btnText}}
            </button>
            <!-- </div> -->
        </div>
    </div>
</div>