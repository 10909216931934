<div class="flex flex-col min-w-80 sm:min-w-160 -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8  mt-5">
        <div>
            <div *ngIf="data.type==1" class="text-2xl font-medium ">Create New Talent Folder</div>
            <div *ngIf="data.type==2" class="text-2xl font-medium ">Rename Talent Folder</div>
        </div>
        <button mat-icon-button class="bg-gray-300" (click)="close()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <form class="bg-gray-50 flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" [formGroup]="composeForm"
        (ngSubmit)="submitForm()">

        <!-- To -->
        <mat-form-field>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:folder-open'"></mat-icon>
            <mat-label>Folder name</mat-label>
            <input matInput formControlName="folder">
            <mat-hint>Folder name should be under 50 characters.</mat-hint>
            <mat-error *ngIf="composeForm.get('folder').hasError('required')">
                Please enter a folder name.
            </mat-error>
            <mat-error *ngIf="composeForm.get('folder').hasError('maxlength')">
                Folder name cannot exceed 50 characters.
            </mat-error>
        </mat-form-field>

        <!-- Actions -->
        <div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
            <div class="flex items-center ml-auto mt-4 sm:mt-0">
                <button matDialogClose class="order-last sm:order-last mr-3" mat-flat-button (click)="close()">
                    Cancel
                </button>
                <button type="submit" *ngIf="data.type==1" class="order-last sm:order-last" mat-flat-button
                    [color]="'primary'" [disabled]="!composeForm.valid || loader">
                    Add
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
                <button type="submit" *ngIf="data.type==2" class="order-last sm:order-last" mat-flat-button
                    [color]="'primary'" [disabled]="!composeForm.valid || loader">
                    Update
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>