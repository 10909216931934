export const environment = {
    production: true,
    appName: "Skillsme",
    envName: "PRO",
    apiUrl: "https://app.skillsme.ai/api/",
    resourceUrl: "https://app.skillsme.ai/",
    baseUrl: "https://app.skillsme.ai",
    imgUrl: "https://app.skillsme.ai/",
    profileGeneratebaseUrl: 'https://app.skillsme.ai/api/profile-gen/',
    websiteUrl: "https://web.skillsme.ai/",
    githubRedirectUrl: "https://web.skillsme.ai/",
    githubClientId: "63ef5303ff397ac0b132",
    gitlabClientId: '8f5b38214c586c39820d7b7d40a98a9b2f40fc267bf720a2750cf84bd4e2d3ae',
    gitlabCodeVerifier: 'sdjfhgjsghdfjhsvdfjhgsjdfhgjshgfjhs53434vdjfhjshgdfjhsd43434fjhgsjdhfg84389jshdgfbnmn',
    gitlabState: '123456',
    gitlabScope: 'read_user+read_api+read_repository+read_registry',
    stackoverflowClientId: '20310',
    stackoverflowState: '123456',
    stackoverflowScope: 'no_expiry',
    defaultLogoBanner: "api/profile-gen/general/s3/get/logo_banners%2F12356%2F1635053736247.png"
};
