<div class="flex flex-col min-w-90 max-w-160 h-auto -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-1 items-center justify-between h-16 px-6  mt-5">
        <div>

            <div class="text-2xl font-medium ">{{data.title}}</div>
        </div>
        <button mat-icon-button class="bg-gray-300" (click)="close()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <div class="bg-gray-50 flex flex-col flex-auto p-6  overflow-y-auto">

        <!-- To -->
        <p class="mb-6 font-medium">
            {{data.message}}
        </p>

        <!-- Actions -->
        <div class="flex flex-row sm:items-center justify-between mt-0">
            <div class="flex items-center">
                <div class="flex flex-row pr-2" *ngIf="data.checkbox">
                    <mat-checkbox [color]="'primary'" (change)="changeConfirm()">
                        {{checkboxText}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="flex items-center mt-0 ml-auto">
                <button class="ml-auto sm:mt-0 sm:ml-0" mat-flat-button (click)="close()" *ngIf="data.cancelButtontText">
                    {{data.cancelButtontText}}
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
                <button class="ml-auto sm:mt-0 sm:ml-0" mat-flat-button [color]="data.submitButtonType" (click)="submit()">
                    {{data.submitButtontText}}
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>

    </div>
</div>