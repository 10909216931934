import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { UserService } from 'app/core/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'add-folder',
    templateUrl: './add-folder.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AddFolderComponent implements OnInit {
    composeForm: FormGroup;
    loader: boolean = false;
    userId: any;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;
    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<AddFolderComponent>,
        private _formBuilder: FormBuilder,
        private _businessService: BusinessService,
        private _userService: UserService,
        private _matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._userService.user$.subscribe((f) => {
            console.log('user', f);
            this.userId = f.id;
        });
        this.isMobile();
        // Create the form
        this.composeForm = this._formBuilder.group({
            folder: ['', [Validators.required, Validators.maxLength(50)]],
        });
        if (this.data.type == 2) {
            this.composeForm.patchValue({
                folder: this.data.folder.title
            })
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------






    /**
     * link the linkedin
     */
    close(): void {
        this.addUserAction('business_folder_popup_close_click');
        this.matDialogRef.close(false);
    }


    /**
   * create new folder
   */
    createNew() {
        console.log('createNew is triggered');
        if (!this.loader) {
            this.loader = true;
            this._businessService.createNewFolder(this.composeForm.value.folder).subscribe((k) => {
                this._businessService.getAllMyFolders().subscribe((k) => {
                    this._userService.folder = k;
                }, err => {
                    console.error('getAllMyFolders failed, error: ', err);
                    this.loader = false;
                })
                // this.loader=true;
                this.matDialogRef.close({
                    submit: true,
                    folder: k[0]
                });
            }, err => {
                console.error('createNewFolder failed, error: ', err);
                this.loader = false;
            })
        }

    }

    renameFolder() {
        if (!this.loader) {
            this.loader = true;
            this._businessService.renameFolder(this.data.folder.id, this.composeForm.value.folder).subscribe((k) => {
                this._businessService.getAllMyFolders().subscribe((k) => {
                    this._userService.folder = k;
                }, err => {
                    console.error('getAllMyFolders failed, error: ', err);
                    this.loader = false;
                })
                this.matDialogRef.close({
                    submit: true,
                    folder: k[0]
                });
            }, err => {
                console.error('renameFolder failed, error: ', err);
                this.loader = false;
            })
        }

    }

    submitForm() {
        console.log('this.composeForm.valid', this.composeForm.valid)
        if (this.composeForm.valid) {
            if (!this.loader) {
                if (this.data.type == 1) {
                    this.addUserAction('business_folder_popup_create_submit_click');
                    this.createNew();
                } else {
                    this.addUserAction('business_folder_popup_rename_submit_click');
                    this.renameFolder();
                }
            }
        }
        else {
            this._matSnackBar.open('Please enter a folder name to create.', 'Close', {
                duration: 4000
            });
        }
    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.userId,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }


}
