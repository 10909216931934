<!-- Item wrapper -->
<div class="fuse-vertical-navigation-item-wrapper" style="margin:0px;"
    [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle" [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <a class="fuse-vertical-navigation-item-wrapper fuse-vertical-navigation-item"
        *ngIf="item.link && !item.externalLink && !item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        (click)="addUserAction('business_side_nav_folder_click');">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with an external link -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && item.externalLink && !item.function && !item.disabled"
        [href]="item.link">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a function -->
    <div style="margin:0 12px;" class="fuse-vertical-navigation-item"
        *ngIf="!item.link && item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active ,'justify-center mt-2 rounded-full bg-accent-200 hover:bg-accent-300':item.meta=='button'}"
        (click)="addNewFolder(item)">

        <!-- (click)="item.function(item)" -->
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item with an internal link and function -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && !item.externalLink && item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with an external link and function -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && item.externalLink && item.function && !item.disabled"
        [href]="item.link" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a no link and no function -->
    <div class="fuse-vertical-navigation-item" *ngIf="!item.link && !item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item is disabled -->
    <div class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled" *ngIf="item.disabled">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <mat-icon class="fuse-vertical-navigation-item-icon text-blue-600" [ngClass]="item.classes?.icon" *ngIf="item.icon"
        [svgIcon]="item.icon"></mat-icon>

    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
        <div class="fuse-vertical-navigation-item-title">
            <span [ngClass]="item.classes?.title" class="break-all">
                {{item.title}}
            </span>
        </div>
        <div class="fuse-vertical-navigation-item-subtitle" *ngIf="item.subtitle">
            <span [ngClass]="item.classes?.subtitle">
                {{item.subtitle}}
            </span>
        </div>
    </div>
    <button *ngIf="item?.meta=='folder'" mat-icon-button [matMenuTriggerFor]="userMenu" class="ml-auto"
        [ngClass]="item.classes?.icon"
        (click)="$event.stopPropagation();$event.preventDefault();addUserAction('business_side_nav_folder_menu_click');">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:dots-vertical'">
        </mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="renameFolder(item)">Rename</button>
        <button mat-menu-item (click)="deleteFolder(item)">Delete</button>
    </mat-menu>
    <!-- Badge -->
    <div class="fuse-vertical-navigation-item-badge" *ngIf="item.badge">
        <div class="fuse-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
            {{item.badge.title}}
        </div>
    </div>

</ng-template>