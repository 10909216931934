import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { MatDialog } from '@angular/material/dialog';
import { AddFolderComponent } from 'app/layout/common/add-folder/add-folder.component';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from 'app/modules/admin/common/confirmation-dialog/confirmation-dialog.component';
import { BusinessService } from 'app/core/services/business.service';
import { UserService } from 'app/core/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralService } from 'app/core/services/general.service';
import { User } from 'app/core/user/user.model';
@Component({
    selector: 'fuse-vertical-navigation-basic-item',
    templateUrl: './basic.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy {
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    loading: boolean = false;
    folderSub: Subscription;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;
    user: User;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _matDialog: MatDialog,
        private _router: Router,
        private _businessService: BusinessService,
        private _generalService: GeneralService,
        private _userService: UserService,
        private _matSnackBar: MatSnackBar,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        this.folderSub = this._generalService.listen('folder list updated').subscribe(r => {
            console.log('folder list updated detected');
            this.getMyCurrentFolder();
        })

        this.getMyCurrentFolder();
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.folderSub.unsubscribe();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    addNewFolder() {
        this.addUserAction('business_side_nav_add_new_folder_click');
        console.log('button click is working', this._matDialog)
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(AddFolderComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",
                data: {
                    type: 1
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result.submit == true) {
                    }
                    console.log('linkedin dialog was closed!');
                });
        }
    }

    renameFolder(item) {
        this.addUserAction('business_side_nav_rename_folder_click');
        console.log('button click is working', this._matDialog)
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(AddFolderComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",
                data: {
                    type: 2,
                    folder: item
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result.submit == true) {
                    }
                    console.log('linkedin dialog was closed!');
                });
        }
    }

    deleteFolder(item) {
        this.addUserAction('business_side_nav_delete_folder_click');
        console.log('folder', item, this._router.routerState.snapshot.url)
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Delete folder',
                    message: 'Are you sure you want to delete this folder?',
                    submitButtontText: 'Delete',
                    submitButtonType: 'warn'
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result.submit == true) {

                        console.log('true', item)


                        this._businessService.deleteFolder(item.id).subscribe((k) => {
                            this._matSnackBar.open('Folder deleted.', 'Close', {
                                duration: 4000
                            });
                            this.getMyCurrentFolder();
                        })
                    }
                });
        }
    }

    getMyCurrentFolder() {
        this._userService.user$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((f) => {
            let user = f;
            console.log('getMyCurrentFolder is triggered', user);
            if (user.role == "Business") {
                this._businessService.getAllMyFolders().subscribe((k) => {
                    console.log('folder list', k);
                    if (k == null) {
                        this._userService.folder = [];
                        this._router.navigate(["/business/welcome"])
                    }
                    else {
                        this._userService.folder = k;

                        let currentFolderUrl = this._router.routerState.snapshot.url;
                        console.log('currentFolderUrl: ', currentFolderUrl);
                        const currentFolderId = parseInt(currentFolderUrl.split('/')[currentFolderUrl.split('/').length - 1]);
                        console.log('currentFolderId: ', currentFolderId);
                        if (Array.isArray(k)) {
                            console.log('k.findIndex(f => f.id === ', currentFolderId, ') === -1: ', k.findIndex(f => f.id === currentFolderId) === -1)
                            if (k.findIndex(f => f.id === currentFolderId) === -1) {
                                this._router.navigate(["/business/folder/" + k[0].id])
                            }
                        }
                    }

                });
            }
        });


    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.user.id,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }
}
