import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _folder: ReplaySubject<[]> = new ReplaySubject<[]>(1);
    baseUrl = environment.apiUrl;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        localStorage.setItem('tri-user', JSON.stringify(value));
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }


    /**
     * Setter & getter for folder
     *
     * @param value
     */
    set folder(folders) {
        // Store the value
        this._folder.next(folders);
    }

    get folder$(): Observable<[]> {
        return this._folder.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                // Execute the observable
                this._user.next(response);
            })
        );
    }

    /**
     * Get current user lite information
     *
     */
    getCurrentUserLite(): Observable<any> {
        return this._httpClient.get(this.baseUrl + 'user/lite-profile');
    }

}
