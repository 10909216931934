import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'business/welcome' },

    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'business/welcome' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            { path: 'profile-link', loadChildren: () => import('app/modules/auth/profile-link/profile-link.module').then(m => m.AuthProfileLinkModule) },
            { path: 'profile-success', loadChildren: () => import('app/modules/auth/profile-success/profile-success.module').then(m => m.ProfileSuccessModule) },
            { path: 'profile-disconnect', loadChildren: () => import('app/modules/auth/profile-disconnect/profile-disconnect.module').then(m => m.ProfileDisconnectModule) },
            { path: 'profile-share-management', loadChildren: () => import('app/modules/auth/profile-share-management/profile-share-management.module').then(m => m.ProfileShareManagementModule) },
            { path: 'profile-share-management-experience', loadChildren: () => import('app/modules/auth/profile-share-management-experience/profile-share-management-experience.module').then(m => m.ProfileShareManagementExperienceModule) },
            { path: 'profile-share-management-stackoverflow', loadChildren: () => import('app/modules/auth/profile-share-management-stackoverflow/profile-share-management-stackoverflow.module').then(m => m.ProfileShareManagementStackoverflowModule) },
            { path: 'profile-delete', loadChildren: () => import('app/modules/auth/profile-delete/profile-delete.module').then(m => m.ProfileDeleteModule) },
            { path: 'email-unsubscription', loadChildren: () => import('app/modules/auth/email-unsubscription/email-unsubscription.module').then(m => m.AuthEmailUnsubscriptionModule) },
            { path: 'applicant/email-unsubscription', loadChildren: () => import('app/modules/auth/applicant-email-unsubscription/applicant-email-unsubscription.module').then(m => m.ApplicantEmailUnsubscriptionModule) },
            { path: 'profile-refetch', loadChildren: () => import('app/modules/auth/profile-refetch/profile-refetch.module').then(m => m.ProfileRefetchModule) },
            { path: 'github-redirect', loadChildren: () => import('app/modules/auth/github-redirect/github-redirect.module').then(m => m.GithubRedirectModule) },
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },


    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'business', children: [
                    { path: '', loadChildren: () => import('app/modules/admin/business/business-folder/business-folder.module').then(m => m.BusinessFolderModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/business/business-no-folder/business-no-folder.module').then(m => m.BusinessNoFolderModule) }
                ]
            }
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'admin', children: [
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/company/company.module').then(m => m.CompanyListModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/user-need-rating/user-need-rating.module').then(m => m.UserNeedRatingModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/user-completed/user-completed.module').then(m => m.UserCompletedModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/admin-user-folder/admin-user-folder.module').then(m => m.AdminUserFolderModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/coding-challenge-list/coding-challenge-list.module').then(m => m.CodingChallengeListModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/joblist/joblist.module').then(m => m.JobListModule) },
                    { path: '', loadChildren: () => import('app/modules/admin/admin-panel/highlighted-users/highlighted-users.module').then(m => m.HighlightedUserModule) },
                ]
            }
        ]
    },
    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [

            // Dashboards

            // Pages
            {
                path: 'pages', children: [


                    // Error
                    {
                        path: 'error', children: [
                            { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
                            { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module) }
                        ]
                    },

                    // Maintenance
                    { path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },

                    // Profile
                    { path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then(m => m.ProfileModule) },
                ]
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
