import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';

@Component({
    selector: 'csv-confirmation-dialog',
    templateUrl: './csv-confirmation-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CsvConfirmationDialogComponent implements OnInit {

    userActionLoading: any = false;

    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<CsvConfirmationDialogComponent>,
        private _businessService: BusinessService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        console.log('this.data', this.data)

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------




    close(): void {
        this.addUserAction('business_invite_csv_warning_popup_cancel_click');
        this.matDialogRef.close(false);
    }

    discard(): void {
        this.addUserAction('business_invite_csv_warning_popup_discard_click');
        this.matDialogRef.close(true);
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.data.userId,
                'action': action,
                'device': this.data.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }

}
