import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { UserService } from 'app/core/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { environment } from 'environments/environment';
@Component({
    selector: 'business-logo',
    templateUrl: './business-logo.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BusinessLogoComponent implements OnInit {
    @ViewChild('imageuploader')
    imageuploader: ElementRef;
    baseImgUrl: any = environment.resourceUrl;

    composeForm: FormGroup;

    currentFolderId: number;

    folders: any = [];
    userList: any = [];

    userForm = new FormArray([], [Validators.required]);
    loader: any = false;
    public files: NgxFileDropEntry[] = [];
    fileUploaded: any = false;
    uploadedFile: any;
    userId: any;
    currentBannerUrl: any;
    currentBannerAvailable: any = false;
    currentUser: any;
    loadFinish: any = false;

    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<BusinessLogoComponent>,
        private _formBuilder: FormBuilder,
        private _businessService: BusinessService,
        private _matSnackBar: MatSnackBar,
        private _changeDetectorRef: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form


        // console.log('this.data', this.data)
        // this.folders = this.data.folder;
        this.composeForm = this._formBuilder.group({

            name: [''],
        });

        this._userService.user$.subscribe((f) => {
            console.log('user', f);
            this.currentUser = f;
            this.userId = f.id;
            this._businessService.getBusinessBanner(this.userId).subscribe((banner) => {
                console.log('this.banner', banner);
                if (banner.url) {
                    this.currentBannerAvailable = true;
                    if (banner.url.includes('%2F')) {
                        this.currentBannerUrl = this.baseImgUrl + banner.url;
                    }
                    else {
                        this.currentBannerUrl = banner.url;
                    }
                }
                this.loadFinish = true;
            }, (err) => {
                this.currentBannerAvailable = false;
                this.loadFinish = true;
            })
            // this.composeForm.patchValue({
            //     name: f.name
            // })
        });


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the copy field with the given field name
     *
     * @param name
     */
    openImageUpload(): void {
        this.imageuploader.nativeElement.click();
    }

    /**
     * Save and close
     */
    saveAndClose(): void {
        this.matDialogRef.close();
    }

    saveChanges(): void {

        if (this.fileUploaded == true) {
            this.loader = true;
            console.log('file', this.uploadedFile)
            let bannerFile = new FormData();

            bannerFile.append("file", this.uploadedFile);

            this._businessService.uploadBannerPhotoV2(this.userId, bannerFile).subscribe((f) => {
                console.log('f', f);

                this._matSnackBar.open('Changes saved.', 'Close', {
                    duration: 4000
                });
                this.loader = false;
                this.matDialogRef.close();
            }, (err) => {
                this.loader = false;
                console.log('error', err);
                this._matSnackBar.open('Something went wrong,try again.', 'Close', {
                    duration: 4000
                });
            })
        }
        else {
            this.matDialogRef.close();
        }

    }








    openFile(event: any) {
        console.log('openFile is triggered', event);
        const input = event.target;
        let a = document.getElementById('uploadedImage');
        a['src'] = URL.createObjectURL(input.files[0]);
        this.uploadedFile = input.files[0];
        this.fileUploaded = true;
    }


    public dropped(files: NgxFileDropEntry[]) {
        this.files = files;
        for (const droppedFile of files) {
            // console.log('files', droppedFile)


            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // Here you can access the real file
                    console.log('files', droppedFile.relativePath, file);

                    let a = document.getElementById('uploadedImage');
                    a['src'] = URL.createObjectURL(file);
                    this.uploadedFile = file;
                    this.fileUploaded = true;
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }


    clearImage() {
        this.fileUploaded = false;
        this.currentBannerAvailable = false;
    }


    checkDisable() {
        if (this.loader == false) {
            if (this.currentBannerAvailable == false && this.fileUploaded == false) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }


    }


}
