import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CsvConfirmationDialogComponent } from 'app/layout/common/csv-confirmation-dialog/csv-confirmation-dialog.component';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { environment } from 'environments/environment';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'invite-csvupload',
    templateUrl: './invite-csvupload.component.html',
    encapsulation: ViewEncapsulation.None
})
export class InviteCsvuploadComponent implements OnInit {
    @ViewChild('csvuploader')
    csvuploader: ElementRef;

    composeForm: FormGroup;

    currentFolderId: number;

    folders: any = [];
    userList: any = [];
    loading: boolean = false;

    userForm = new FormArray([], [Validators.required]);
    loader: any = false;
    public files: NgxFileDropEntry[] = [];
    errorCode: number = 0;
    user: User;
    foldUrl: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;
    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<InviteCsvuploadComponent>,
        private _formBuilder: FormBuilder,
        private _businessService: BusinessService,
        private _matSnackBar: MatSnackBar,
        private _changeDetectorRef: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });
        // Create the form

        console.log('this.data', this.data)
        this.folders = this.data.folder;
        let folder = this.data.folder.find(f => f.id === this.data.currentFolderId);
        if (folder) {
            this.foldUrl = environment.websiteUrl + "profile-link/company/" + folder.url;
        }
        this.composeForm = this._formBuilder.group({
            folder: ['', [Validators.required]],
            subject: ['', [Validators.required]],
            message: ['', [Validators.required]]
        });

        this.checkValidity();

        this.composeForm.valueChanges.subscribe(() => {
            this.checkValidity();
        })

        if (this.data.currentFolderId && this.folders.findIndex(f => f.id === this.data.currentFolderId) !== -1) {
            this.currentFolderId = this.data.currentFolderId;
            let message = this.folders.find(f => f.id === this.currentFolderId).message;
            let subject = this.folders.find(f => f.id === this.currentFolderId).subject;
            if (!message || message === '') {

                let folder = this.data.folder.find(f => f.id === this.data.currentFolderId);
                this.foldUrl = environment.websiteUrl + "profile-link/company/" + folder.url;
                message =

                    `Hi FIRST_NAME,

Thank you for your interest in this position. Congratulations, you have been shortlisted and we would like to invite you to link your external accounts (Github, Stack Overflow, Linkedin) as part of our recruitment process.

The link for proceeding to the next step is at the end of this email. It was designed to evaluate your skills and help us to understand your approach in situations relevant to this position.

Please complete this as soon as possible to ensure you are part of the process.

Congratulations again on this exciting opportunity and the best of luck.

Please feel free to contact us if you have any questions.

The link is attached below. Please make sure to click it to proceed. 

Sincerely,
                    
`
            }
            this.composeForm.patchValue({
                folder: this.data.currentFolderId,
                message: message,
                subject: subject
            })
            this._changeDetectorRef.markForCheck();
        }

        this.composeForm.valueChanges.subscribe(r => {
            console.log('r: ', r);
            if (r.folder !== this.currentFolderId) {
                this.currentFolderId = r.folder;
                let message = this.folders.find(f => f.id === this.currentFolderId).message;
                let subject = this.folders.find(f => f.id === this.currentFolderId).subject;
                if (!message || message === '') {
                    let folder = this.data.folder.find(f => f.id === this.composeForm.value.folder);
                    this.foldUrl = environment.websiteUrl + "profile-link/company/" + folder.url;
                    message =
                        `Hi FIRST_NAME,

Thank you for your interest in this position. Congratulations, you have been shortlisted and we would like to invite you to link your external accounts (Github, Stack Overflow, Linkedin) as part of our recruitment process.

The link for proceeding to the next step is at the end of this email. It was designed to evaluate your skills and help us to understand your approach in situations relevant to this position.

Please complete this as soon as possible to ensure you are part of the process.

Congratulations again on this exciting opportunity and the best of luck.

Please feel free to contact us if you have any questions.

The link is attached below. Please make sure to click it to proceed. 

Sincerely,
                    
`
                }
                this.composeForm.patchValue({
                    message: message,
                    subject: subject
                })
                this._changeDetectorRef.markForCheck();
            }
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the copy field with the given field name
     *
     * @param name
     */
    openCsvUpload(): void {
        this.addUserAction('business_invite_csv_popup_upload_click');
        this.csvuploader.nativeElement.click();


    }

    /**
     * Save and close
     */
    saveAndClose(): void {
        this.addUserAction('business_invite_csv_popup_close_click');
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(CsvConfirmationDialogComponent, {
                data: {
                    btnText: 'Close',
                    userId: this.user.id,
                    mobileDevice: this.mobileDevice
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result == true) {
                        // Close the dialog
                        this.matDialogRef.close();
                    }
                })
        }
    }

    /**
     * Check the message
     */
    validate() {
        console.log('validate is triggered');
        this._changeDetectorRef.markForCheck();
        return new Promise(resolve => {
            this._changeDetectorRef.markForCheck();
            resolve(this.composeForm.valid);
        })
    }

    /**
     * Send the message
     */
    async invite() {
        this.addUserAction('business_invite_csv_popup_submit_click');
        const v = await this.validate();
        this._changeDetectorRef.markForCheck();
        if (v) {
            if (this.loader === false) {
                console.log('loopstart')
                this.loader = true;
                let users = [];
                let errorReported = false;
                await Promise.all(
                    this.userForm.controls.map(async (user): Promise<number> => {
                        users.push({
                            fname: user.value.fname,
                            lname: user.value.lname,
                            email: user.value.email
                        })
                        return new Promise((resolve, reject) => {
                            if (!errorReported) {
                                this._businessService.createInvitation(
                                    this.composeForm.value.folder,
                                    user.value.email,
                                    user.value.fname,
                                    user.value.lname,
                                    this.composeForm.value.subject,
                                    this.composeForm.value.message, this.user.email).subscribe((k) => {
                                        console.log('invite', k)
                                        resolve(1);
                                    }, err => {
                                        console.error('createInvitation failed, error: ', err);
                                        errorReported = true;
                                        if (err.status === 404) {
                                            if (Array.isArray(this.folders)) {
                                                let index = this.folders.findIndex(f => f.id === this.composeForm.value.folder)
                                                if (index > -1) {
                                                    this.folders.splice(index, 1);
                                                    this._matSnackBar.open('Folder deleted, please choose another folder. ', 'Close');
                                                }
                                            }
                                            this._changeDetectorRef.markForCheck();
                                        }
                                        else {
                                            this._matSnackBar.open('An error occurs, please try again later. ', 'Close');
                                        }
                                        this.loader = false;
                                        reject(0);
                                    })
                            }
                            else {
                                reject(0);
                            }
                        });
                    })
                )
                    .then(() => {
                        console.log('user final', this.userForm.valid, users)
                        console.log('loopfinish');
                        this._matSnackBar.open('Invitation sent.', 'Close', {
                            duration: 4000
                        });
                        this._businessService.emitChange({
                            folderId: this.composeForm.value.folder,
                            type: 'invitation'
                        });
                        this.matDialogRef.close(true);
                    })
                    .catch(err => {
                        console.log('error catched: ', err);
                        errorReported = false;
                    })
            }
        }
        else {
            console.log('this.errorCode: ', this.errorCode);
            this.loader = false;
            this._matSnackBar.open('Please check your invitation content before sending. ', 'Close');
        }
    }

    /**
     * Send the message
     */
    async sendTestInvitationEmail() {
        this.addUserAction('business_invite_csv_popup_send_test_mail_click');
        this._changeDetectorRef.markForCheck();
        console.log('start sending test email')
        if (!this.loader) {
            this.loader = true;

            console.log('this.composeForm.value.folder: ', this.composeForm.value.folder);
            if (this.composeForm.value.folder != '' && this.composeForm.value.folder != null) {
                const folder = this.data.folder.find(f => f.id === this.composeForm.value.folder);
                let url = environment.websiteUrl + "/profile-link/company/" + folder.url
                let email = JSON.parse(localStorage.getItem('tri-user'))['email'];
                if (url && email) {
                    this._businessService.sendTestInvitationEmail(email, this.composeForm.value.folder, url, this.composeForm.value.subject, this.composeForm.value.message).subscribe(() => {
                        this._matSnackBar.open('Invitation email sent.', 'Close', {
                            duration: 4000
                        });
                        this.loader = false;
                    })
                }
                else {
                    this._matSnackBar.open('Session expired. Please log in again.', 'Close', {
                        duration: 4000
                    });
                    this.loader = false;
                }
            }
            else {
                this._matSnackBar.open('Please select a folder.', 'Close', {
                    duration: 4000
                });
                this.loader = false;
            }
        }
    }




    /**
     * copy content
     */
    copyMessage(val: string) {
        this.addUserAction('business_invite_csv_popup_message_copy_click');
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this._matSnackBar.open('Message copied', 'Close', {
            duration: 4000
        })
    }


    /**
     * copy url
     */
    copyUrl() {
        this.addUserAction('business_invite_csv_popup_url_copy_click');
        console.log('copyUrl is triggered');
        console.log('this.composeForm.value.folder: ', this.composeForm.value.folder);
        if (this.composeForm.value.folder != '' && this.composeForm.value.folder != null) {
            const folder = this.data.folder.find(f => f.id === this.composeForm.value.folder);
            let val = environment.websiteUrl + "/profile-link/company/" + folder.url
            const selBox = document.createElement("textarea");
            selBox.style.position = "fixed";
            selBox.style.left = "0";
            selBox.style.top = "0";
            selBox.style.opacity = "0";
            selBox.value = val;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand("copy");
            document.body.removeChild(selBox);
            this._matSnackBar.open('Invitation url copied.', 'Close', {
                duration: 4000
            });
        }
        else {
            this._matSnackBar.open('Please select a folder.', 'Close', {
                duration: 4000
            });
        }
    }


    openFile(event: any) {
        this.addUserAction('business_invite_csv_popup_upload_file_select');
        console.log('openFile is triggered', event);
        const input = event.target;

        this.readCsvFile(input.files[0]);
    }

    openManualPopup() {
        this.addUserAction('business_invite_csv_popup_enter_manually_click');
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(CsvConfirmationDialogComponent, {
                data: {
                    btnText: 'Change back to manual input'
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result == true) {
                        this.matDialogRef.close({
                            openinvite: true
                        });
                    }

                });
        }

    }

    createFormArray() {
        this.userList.map((k) => {
            const group = this._formBuilder.group({
                email: [k.email, [Validators.required, Validators.email]],
                fname: [k.firstName, [Validators.required]],
                lname: [k.lastName, [Validators.required]],
            })
            group.controls.email.markAsTouched();
            group.controls.fname.markAsTouched();
            group.controls.lname.markAsTouched();
            this.userForm.push(group);
        })
    }

    deleteUser(i) {
        this.addUserAction('business_invite_csv_popup_delete_user_click');
        this.userForm.removeAt(i);
        this.userList.splice(i, 1);
    }

    checkValidity(): boolean {
        console.log('validation', this.composeForm.valid, this.userList.length, this.userForm.controls.length, this.userForm.valid)
        console.log('this.userForm: ', this.userForm);
        this._changeDetectorRef.markForCheck();
        if (this.composeForm.valid) {
            if (this.userList.length != 0) {
                return !this.userForm.valid;
                // if (this.userForm.controls.length != 0) {
                //     // this is always false
                //     return false
                // } else {
                //     return true
                // }
            } else {
                return true
            }
        } else {
            return true
        }
    }


    public dropped(files: NgxFileDropEntry[]) {
        this.addUserAction('business_invite_csv_popup_file_drop');
        this.files = files;
        for (const droppedFile of files) {
            // console.log('files', droppedFile)


            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // Here you can access the real file
                    console.log('files', droppedFile.relativePath, file);

                    this.readCsvFile(file);
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    /**
     * Remove special character
     */
    excludeSpecial(str) {
        str = str.replace(/\\f/g, '').replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\t/g, '').replace(/[\'\"\\\/\b]/g, '').replace(/(\r\n|\n|\r)/gm, "");;
        return str;
    }

    readCsvFile(file) {
        const reader = new FileReader();
        reader.onload = (() => {
            if (reader.result) {
                // console.log('reader.result: ', reader.result);
                // console.log('typeof reader.result: ', typeof reader.result);

                const array = reader.result.toString().split(/\n/);
                const array2 = [];
                if (array.length > 0) {
                    let indexEmail = -1;
                    let indexFirstName = -1;
                    let indexLastName = -1;
                    let indexName = -1;
                    for (let i = 0; i < array.length; i++) {
                        const subarray2 = array[i].split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g);
                        console.log('subarray2: ', subarray2);
                        console.log('i: ', i);
                        if (i === 0) {
                            // this is the column title row 
                            indexEmail = subarray2.findIndex(e => e.toLowerCase().includes('email'));
                            indexFirstName = subarray2.findIndex(e =>
                                e.toLowerCase().includes('firstname') ||
                                e.toLowerCase().includes('fname') ||
                                e.toLowerCase().includes('first_name') ||
                                e.toLowerCase().includes('f_name') ||
                                (e.toLowerCase().includes('first') && e.toLowerCase().includes('name'))
                            );
                            indexLastName = subarray2.findIndex(e =>
                                e.toLowerCase().includes('lastname') ||
                                e.toLowerCase().includes('lname') ||
                                e.toLowerCase().includes('last_name') ||
                                e.toLowerCase().includes('l_name') ||
                                (e.toLowerCase().includes('last') && e.toLowerCase().includes('name'))
                            );
                            indexName = subarray2.findIndex(e =>
                                e.toLowerCase().includes('name') ||
                                e.toLowerCase().includes('Name')
                            );
                        }
                        else {
                            // this is a data row 
                            let email;
                            let firstName;
                            let lastName;
                            let name;
                            let fname = '';
                            let lname = '';
                            if (indexEmail !== -1) {
                                let checkemail = subarray2[indexEmail];
                                if (checkemail) {
                                    let newEmailStr = checkemail.replace("mailto:", "")
                                    let emails = newEmailStr.split(';');
                                    email = this.excludeSpecial(emails[0]);
                                }
                            }

                            if (indexName !== -1) {
                                name = subarray2[indexName];
                                if (name) {
                                    let names = name.split(' ');
                                    if (names.length == 1) {
                                        fname = this.excludeSpecial(names[0]);
                                        lname = '';
                                    }
                                    else {

                                        names.map((k, index) => {
                                            if (index != names.length - 1) {

                                                fname = fname + ' ' + k;
                                            }
                                        })
                                        lname = this.excludeSpecial(names[names.length - 1]);
                                        console.log('names', names)
                                    }
                                }
                                else {
                                    fname = '';
                                    lname = '';
                                }
                            }
                            if (indexFirstName !== -1) {
                                firstName = this.excludeSpecial(subarray2[indexFirstName]);
                            }
                            else {
                                firstName = this.excludeSpecial(fname.trim());
                            }
                            if (indexLastName !== -1) {
                                lastName = this.excludeSpecial(subarray2[indexLastName]);
                            }
                            else {
                                lastName = this.excludeSpecial(lname);
                            }
                            if (email || firstName || lastName) {
                                const object = {
                                    email: email,
                                    firstName: firstName,
                                    lastName: lastName,
                                }
                                array2.push(object);
                            }
                        }
                    }
                }
                this.userList = array2;
                this.createFormArray();
                // console.log('array: ', array);
                // console.log('array2: ', array2);
            }
        });
        reader.readAsText(file, 'utf-8');
    }

    checkFormValid() {
        console.log('user form', this.userForm)
    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.user.id,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }
}
