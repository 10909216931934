<div class="flex flex-col w-160 -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 mt-5 ">
        <div class="text-3xl font-medium">Invite Talents by CSV</div>
        <button mat-icon-button class="bg-gray-300" (click)="saveAndClose()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <form class="flex flex-col flex-auto  p-6 sm:p-8 overflow-y-auto" [formGroup]="composeForm" autocomplete="off"
        (submit)="invite()">

        <div class="bg-white mb-6 border-4 border-dashed" *ngIf="userList.length==0">

            <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" accept=".csv"
                dropZoneClassName="flex w-full h-48   items-center justify-center ">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <!-- <button type="button" (click)="openFileSelector()">Browse Files</button> -->
                    <div class=" flex text-primary items-center">
                        <mat-icon class="icon-size-7 text-primary" [svgIcon]="'mat_outline:upload_file'"></mat-icon>
                        <span class="text-xl font-semibold cursor-pointer select-none hover:underline"
                            (click)="openCsvUpload()">
                            Drop or upload csv
                        </span>
                    </div>
                </ng-template>
            </ngx-file-drop>

            <input type="file" accept=".csv" #csvuploader (change)="openFile($event)" style="display: none;">
        </div>
        <div class="w-full h-48 p-4 border-2 border bg-white overflow-y-auto mb-6" *ngIf="userList.length!=0">
            <div class="grid grid-cols-12 gap-4 pb-2 border-b mb-4">
                <div class="col-span-3 items-center justify-center font-semibold">
                    First name
                </div>
                <div class="col-span-3 items-center justify-center font-semibold">
                    Last name
                </div>
                <div class="col-span-3 items-center justify-center font-semibold">
                    Email
                </div>
            </div>

            <div class="w-full " *ngFor="let user of userForm.controls;let i = index;">
                <!-- fname -->
                <div class="grid grid-cols-12 gap-4 " [formGroup]="user">

                    <mat-form-field class="col-span-3">
                        <input placeholder="First name" matInput [formControlName]="'fname'" autocomplete="false">
                    </mat-form-field>

                    <mat-form-field class="col-span-3">
                        <input placeholder="Last name" matInput [formControlName]="'lname'" autocomplete="false">
                    </mat-form-field>

                    <!-- lname -->
                    <mat-form-field class="col-span-5">
                        <input placeholder="Email" matInput [formControlName]="'email'" autocomplete="false">
                    </mat-form-field>
                    <div class="col-span-1 ">
                        <mat-icon class="icon-size-6 cursor-pointer mt-3" [svgIcon]="'mat_outline:delete'"
                            (click)="deleteUser(i)"></mat-icon>
                    </div>
                </div>

            </div>
        </div>

        <mat-form-field class="w-full">
            <!-- <input placeholder="select folder" matInput [formControlName]="'cc'"> -->
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:folder-open'"></mat-icon>
            <mat-select placeholder="Select folder" [formControlName]="'folder'">
                <mat-option *ngFor="let folder of folders" [value]="folder.id"
                    (click)="addUserAction('business_invite_csv_popup_folder_select_click');">{{folder.title}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <!-- <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:user-circle'"></mat-icon> -->
            <input name="subject" type="text" autocomplete="nope" placeholder="Subject" matInput
                [formControlName]="'subject'">
        </mat-form-field>

        <mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full">
            <textarea matInput cdkTextareaAutosize [cdkAutosizeMinRows]="4" [cdkAutosizeMaxRows]="4"
                placeholder="Invitation message" [formControlName]="'message'"></textarea>
        </mat-form-field>
        <!-- <div class="margin-bottom-thin my-mat-hint" *ngIf="errorCode === 0">Enter your email content here.</div> -->
        <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="composeForm.get('message').hasError('required')">
            Enter your invitation email content here.</div>
        <!-- <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 1">Please replace "POSITION_NAME" with the name of this position.</div>
        <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 2">Please replace "COMPANY_NAME" with your business name.</div>
        <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 3">Please replace "DEADLINE" with the deadline of submission.</div> -->
        <!-- <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 4">Please click "Copy link" and paste the link in your email content.</div> -->


        <small class="mt-2">
            "FIRST_NAME" and "LAST_NAME" will be replaced with talent's first name and last name. <br>
            <!-- Put text between "&#123;" and "&#125;" to make it a hyperlink for invitation. <br>
            Put text between "[" and "]" to make it bold. -->
        </small>
        <small class="mt-1">
            <div class="mb-2">
                <a class="underline hover:no-underline text-primary cursor-pointer"
                    (click)="sendTestInvitationEmail()">Send a test email</a>
            </div>
            <!-- <mat-checkbox [color]="'primary'" [formControlName]="'is_html'" (change)="changeIsHtml()">
                <span class="font-medium">HTML Input</span>
            </mat-checkbox> -->
        </small>

        <!-- Actions -->
        <div class="flex flex-row sm:items-center justify-between mt-4 sm:mt-6">
            <div class="">

                <a class="px-0 mr-5 underline hover:no-underline text-primary cursor-pointer font-medium"
                    (click)="copyUrl()">
                    Copy link
                </a>
                <!-- Save as draft -->
                <a class="px-0  mr-5 underline hover:no-underline text-primary cursor-pointer font-medium"
                    (click)="copyMessage(composeForm.value.message)">
                    <span>Copy invitation</span>
                </a>
                <a class="px-0  underline hover:no-underline text-primary cursor-pointer font-medium"
                    (click)="openManualPopup()">
                    <span>Enter Manually</span>
                </a>
            </div>

            <div class="flex items-center mt-4 sm:mt-0">
                <!-- Send -->
                <button class="order-first sm:order-last" mat-flat-button [color]="'primary'" type="submit"
                    [disabled]="checkValidity() || loader">
                    Invite Talent
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>