import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent implements OnInit {

    confirm: boolean = false;
    loader: boolean = false;
    checkboxText: string;


    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;
    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _businessService: BusinessService,
        private _userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                console.log('user', this.user);
            });
        // Create the form
        console.log('this.data', this.data);
        if (this.data && this.data['checkboxText']) {
            this.checkboxText = this.data.checkboxText;
        }
        else {
            this.checkboxText = 'Do not show this again!'
        }
        this._changeDetectorRef.markForCheck();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    changeConfirm() {
        console.log('changeConfirm is triggered');
        this.confirm = !this.confirm;
        console.log('this.confirm: ', this.confirm);
    }

    /**
     * link the linkedin
     */
    submit(): void {
        this.addUserAction('business_confirmation_popup_submit_click');
        // this.loader = true;
        this.matDialogRef.close({
            submit: true,
            confirm: this.confirm
        });
    }

    /**
     * link the linkedin
     */
    close(): void {
        this.addUserAction('business_confirmation_popup_close_click');
        this.matDialogRef.close(false);
    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.user.role == "Business") {
            if (this.userActionLoading == false) {
                this.userActionLoading = true;
                let actionData = {
                    'userId': this.user.id,
                    'action': action,
                    'device': this.mobileDevice
                }
                console.log('user_action', actionData)
                this._businessService.addUserAction(actionData).subscribe(r => {
                    console.log('user_action stored', r)
                    this.userActionLoading = false;
                }, (err) => {
                    console.log('user_action failed', err)
                    this.userActionLoading = false;
                });
            }
        }
    }




}
