import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { UserService } from 'app/core/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
    selector: 'business-name-edit',
    templateUrl: './business-name-edit.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BusinessNameEditComponent implements OnInit {
    composeForm: FormGroup;

    currentFolderId: number;

    folders: any = [];
    userList: any = [];

    userForm = new FormArray([], [Validators.required]);
    loader: any = false;
    public files: NgxFileDropEntry[] = [];
    fileUploaded: any = false;
    uploadedFile: any;
    userId: any;
    currentUser: any;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;

    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<BusinessNameEditComponent>,
        private _formBuilder: FormBuilder,
        private _businessService: BusinessService,
        private _matSnackBar: MatSnackBar,
        private _changeDetectorRef: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.isMobile();

        // console.log('this.data', this.data)
        // this.folders = this.data.folder;
        this.composeForm = this._formBuilder.group({

            name: ['', [Validators.required, Validators.maxLength(50)]],
        });

        this._userService.user$.subscribe((f) => {
            console.log('user', f);
            this.currentUser = f;
            this.userId = f.id;
            this.composeForm.patchValue({
                name: f.name
            })
        });


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Save and close
     */
    saveAndClose(): void {
        this.addUserAction('business_name_edit_popup_close_click');
        this.matDialogRef.close();
    }

    saveChanges(): void {
        this.addUserAction('business_name_edit_popup_submit_click');
        if (!this.loader) {
            this.loader = true;
            this._businessService.updateBusinessName(this.composeForm.value.name).subscribe((s) => {
                this.currentUser.name = this.composeForm.value.name;
                this._userService.user = this.currentUser;
                this._matSnackBar.open('Business name updated.', 'Close', {
                    duration: 4000
                });
                this.matDialogRef.close();
            })
        }

    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.userId,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }


}
