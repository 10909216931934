<div class="flex flex-col min-w-80 sm:min-w-160 -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8  mt-5">
        <div>

            <div class="text-2xl font-medium ">Change password</div>
        </div>
        <button mat-icon-button class="bg-gray-300" (click)="close()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <!-- (ngSubmit)="submitForm()"  -->
    <form class="bg-gray-50 flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" [formGroup]="resetForm"
    autocomplete="disabled">

        <!-- To -->
        <mat-form-field>
            <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_outline:key"></mat-icon>
            <mat-label>Old password</mat-label>
            <input matInput formControlName="oldPassword" type="password" name="oldPassword" autocomplete="old-password" #oldpasswordField
                (blur)="onBlur()" (focus)="onFocus()">
            <button mat-icon-button type="button"
                (click)="oldpasswordField.type === 'password' ? oldpasswordField.type = 'text' : oldpasswordField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="oldpasswordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="oldpasswordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
            <mat-hint>Enter your old password.</mat-hint>
            <mat-error *ngIf="resetForm.get('oldPassword').hasError('required')">
                Please enter your old password.
            </mat-error>
            <mat-error *ngIf="resetForm.get('oldPassword').hasError('maxlength')">
                Password cannot exceed 50 characters.
            </mat-error>
            <mat-error *ngIf="resetForm.get('oldPassword').hasError('incorrect')">
                Old password is not correct.
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_outline:key"></mat-icon>
            <mat-label>New password</mat-label>
            <input matInput formControlName="newPassword" type="password" name="newPassword" autocomplete="new-password" #newpasswordField>
            <button mat-icon-button type="button"
                (click)="newpasswordField.type === 'password' ? newpasswordField.type = 'text' : newpasswordField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="newpasswordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="newpasswordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
            </button>
            <mat-hint>Enter your new password</mat-hint>
            <mat-error *ngIf="resetForm.get('newPassword').hasError('required')">
                Please enter a new password.
            </mat-error>
            <mat-error *ngIf="resetForm.get('newPassword').hasError('maxlength')">
                Password cannot exceed 50 characters.
            </mat-error>
            <mat-error *ngIf="resetForm.get('newPassword').hasError('minlength')">
                Password should be atleast 6 characters.
            </mat-error>
        </mat-form-field>

        <!-- Actions -->
        <div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
            <div class="flex items-center ml-auto mt-4 sm:mt-0">
                <button matDialogClose class="order-last sm:order-last mr-3" mat-flat-button (click)="close()">
                    Cancel
                </button>
                <button type="submit" (click)="submitForm()" class="order-last sm:order-last" mat-flat-button
                    [color]="'primary'" [disabled]="!resetForm.valid || !oldPasswordMatch || loader">
                    Update
                </button>

            </div>
        </div>
    </form>
</div>