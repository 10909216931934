<div class="flex flex-col w-160 -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 mt-5 ">
        <div class="text-3xl font-medium">Manually Invite Talent</div>
        <button mat-icon-button class="bg-gray-300" (click)="saveAndClose()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>


    <!-- Compose form -->
    <form class="flex flex-col flex-auto  p-6 sm:p-8 overflow-y-auto" [formGroup]="composeForm" autocomplete="off"
        (submit)="invite()">

        <div class="grid grid-cols-12 gap-4">
            <mat-form-field class="col-span-6">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:folder-open'"></mat-icon>
                <mat-select name="folder" placeholder="Select folder" [formControlName]="'folder'">
                    <mat-option *ngFor="let folder of folders" [value]="folder.id"
                        (click)="addUserAction('business_invite_popup_folder_select_click');">{{folder.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-span-6">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:mail'"></mat-icon>
                <input name="email" type="email" autocomplete="nope" placeholder="Email" matInput
                    [formControlName]="'email'">
            </mat-form-field>
        </div>
        <div class="grid grid-cols-12 gap-4">
            <mat-form-field class="col-span-6">
                <!-- <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:user-circle'"></mat-icon> -->
                <input name="fname" type="text" autocomplete="nope" placeholder="First name" matInput
                    [formControlName]="'fname'">
            </mat-form-field>
            <mat-form-field class="col-span-6">
                <!-- <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:user-circle'"></mat-icon> -->
                <input name="lname" type="text" autocomplete="nope" placeholder="Last name" matInput
                    [formControlName]="'lname'">
            </mat-form-field>
        </div>
        <div class="grid grid-cols-12 gap-4">
            <mat-form-field class="col-span-12">
                <!-- <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:user-circle'"></mat-icon> -->
                <input name="subject" type="text" autocomplete="nope" placeholder="Subject" matInput
                    [formControlName]="'subject'">
            </mat-form-field>
        </div>



        <!-- Subject -->
        <!-- <mat-form-field>
            <mat-label>Subject</mat-label>
            <input matInput [formControlName]="'subject'">
        </mat-form-field> -->
        <!-- Message -->
        <mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full">
            <textarea matInput cdkTextareaAutosize [cdkAutosizeMinRows]="4" [cdkAutosizeMaxRows]="4"
                placeholder="Invitation message" [formControlName]="'message'"></textarea>
        </mat-form-field>
        <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="composeForm.get('message').hasError('required')">
            Enter your invitation email content here.</div>
        <!-- <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 1">Please replace "POSITION_NAME" with the name of this position.</div>
        <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 2">Please replace "COMPANY_NAME" with your business name.</div>
        <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 3">Please replace "DEADLINE" with the deadline of submission.</div> -->
        <!-- <div class="text-warn margin-bottom-thin my-mat-hint" *ngIf="errorCode === 4">Please click "Copy link" and paste the link in your email content.</div> -->

        <small class="mt-2">
            "FIRST_NAME" and "LAST_NAME" will be replaced with talent's first name and last name. <br>
            <!-- Put text between "&#123;" and "&#125;" to make it a hyperlink for invitation. <br>
            Put text between "[" and "]" to make it bold.  -->
        </small>
        <small class="mt-1">
            <div class="mb-2">
                <a class="underline hover:no-underline text-primary cursor-pointer"
                    (click)="sendTestInvitationEmail()">Send a test email</a>
            </div>
            <!-- <mat-checkbox [color]="'primary'" [formControlName]="'is_html'" (change)="changeIsHtml()">
                <span class="font-medium">HTML Input</span>
            </mat-checkbox> -->
        </small>
        <!-- Body -->
        <!-- <quill-editor
            class="mt-2"
            [formControlName]="'body'"
            [modules]="quillModules"></quill-editor> -->

        <!-- Actions -->
        <div class="flex flex-row sm:items-center justify-between mt-4 sm:mt-6">
            <div class="">

                <a class="px-0 mr-5 underline hover:no-underline text-primary cursor-pointer font-medium"
                    (click)="copyUrl()">
                    Copy link
                </a>
                <!-- Save as draft -->
                <a class="px-0 mr-5 underline hover:no-underline text-primary cursor-pointer font-medium"
                    (click)="copyMessage(composeForm.value.message)">
                    <span>Copy invitation</span>
                </a>
                <a class="px-0 underline hover:no-underline text-primary cursor-pointer font-medium"
                    (click)="openCsvPopup()">
                    <span>CSV input</span>
                </a>
            </div>

            <div class="flex items-center mt-4 sm:mt-0">
                <!-- Send -->
                <button class="order-first sm:order-last" type="submit" mat-flat-button [color]="'primary'"
                    [disabled]="!validate() || loader">
                    Invite Talent
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>