import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { changePasswordComponent } from 'app/layout/common/change-password/change-password.component';
import { BusinessLogoComponent } from 'app/layout/common/business-logo/business-logo.component';
import { BusinessNameEditComponent } from 'app/layout/common/business-name-edit/business-name-edit.component';
import { BusinessService } from 'app/core/services/business.service';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'userMenu'
})
export class UserMenuComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;

    @Input() showAvatar: boolean = true;
    @Input() isPaidPlan: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    loading: boolean = false;
    dnsVerified: boolean = false;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _matDialog: MatDialog,
        private _businessService: BusinessService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                // console.log('user', this.user)
                this._businessService.checkDNSVerifiedByBusiness(user.email).subscribe((r) => {
                    this.dnsVerified = r;
                    console.log('dns verfied', r)
                }, (err) => {
                    console.log(err);
                });

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this.addUserAction('business_user_menu_signout_click');
        console.log('signOut is triggered');
        this._router.navigate(['/sign-out']);
    }


    changePassword() {
        this.addUserAction('business_user_menu_change_password_click');
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(changePasswordComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",

            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;

                });
        }
    }
    openEditLogoPopup() {
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(BusinessLogoComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",

            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;

                });
        }
    }
    openEditNamePopup() {
        this.addUserAction('business_user_menu_edit_name_click');
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(BusinessNameEditComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",

            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;

                });
        }
    }


    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.user.id,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }


}
