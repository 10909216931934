import { Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService } from '@fuse/components/navigation';
import { InitialData } from 'app/app.types';
import { AddFolderComponent } from 'app/layout/common/add-folder/add-folder.component';
import { InviteComponent } from 'app/layout/common/invite/invite.component';
import { InviteCsvuploadComponent } from 'app/layout/common/invite-csvupload/invite-csvupload.component';
import { MatDialog } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { UserService } from 'app/core/user/user.service';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthService } from 'app/core/auth/auth.service';
import { GeneralService } from 'app/core/services/general.service';
import { ConfirmationDialogComponent } from 'app/modules/admin/common/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    data: InitialData;
    isScreenSmall: boolean;
    navigationOpened: boolean = false;
    navigationData: any = [];
    isPaidPlan: boolean;
    myFolders: any = [];
    business: any;
    isAdmin: any = false;
    currentFolderId: any;

    loading: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('picuploader')
    profileuploader: ElementRef;
    baseImgUrl: any = environment.resourceUrl;

    folderSub: Subscription;
    businessDialogSub: Subscription;
    generalDialogSub: Subscription;
    userSub: Subscription;
    userUpdateSub: Subscription;
    folderPipeSub: Subscription;
    routeSub: Subscription;
    mediaSub: Subscription;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _matDialog: MatDialog,
        private _businessService: BusinessService,
        private _userService: UserService,
        private _domSanitizer: DomSanitizer,
        private _generalService: GeneralService,
        private _matSnackBar: MatSnackBar,
        private _authService: AuthService,
        private _router: Router,

    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        // initiate token expiration function
        let expTime = AuthUtils._getTokenExpirationDate(this._authService.accessToken);
        // console.log('expire date', expTime)
        if (expTime.getTime() > new Date().getTime()) {
            this._authService.startExpireTimer(expTime).then((expired) => {
                console.log('expired', expired)
                this.signOut();
            });
        }
        else {
            this.signOut();
        }

        this.navigationOpened = false;

        this.userUpdateSub = this._generalService.listen('user info updated').subscribe(() => {
            console.log('user info updated detected');
            this.updateCurrentUser();
        })

        this.folderSub = this._businessService.folderIdEmitted$.subscribe(f => {
            if (f['id']) {
                this.currentFolderId = f['id'];
            }
        })

        this.businessDialogSub = this._businessService.dialogEmitted$.subscribe(d => {
            console.log('dialog detected: ', d);
            if (d['name']) {
                if (d['name'] === 'InviteComponent') {
                    if (this.isPaidPlan) {
                        this.openInvite();
                    }
                    else {
                        this.copyInviteLink();
                    }
                }
            }
        })

        this.generalDialogSub = this._generalService.dialogEmitted$.subscribe(r => {

            const dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
                data: {
                    title: r.title,
                    message: r.message,
                    submitButtontText: r.submitButtonText,
                    submitButtonType: r.submitButtonType
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                });
        })

        this.userSub = this._userService.user$.subscribe((f) => {

            console.log('this.business', f)
            this.business = f;
            if (f.plan === 1) {
                this.navigationOpened = true;
                this.isPaidPlan = true;
            }
            else {
                this.navigationOpened = false;
                this.isPaidPlan = false;
            }
            if (f.role == 'Business') {
                this.isAdmin = false;
                this._businessService.getAllMyFolders().subscribe((k) => {
                    if (k != null) {
                        this._userService.folder = k;
                    }
                    else {
                        this._userService.folder = [];

                    }
                });
            }
            else if (f.role == 'Admin') {
                this.isAdmin = true;
                this._businessService.getAllMyFolders().subscribe((k) => {
                    if (k != null) {
                        this._userService.folder = k;
                    }
                    else {
                        this._userService.folder = [];

                    }
                });
                this.navigationData = [
                    {
                        id: 'talent',
                        title: 'Talent',
                        subtitle: 'List of all talents',
                        type: 'group',
                        icon: 'heroicons_outline:document',
                        children: [{
                            id: 'need rate',
                            title: 'Need Rating',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/user/need-rating',
                            url: '/admin/user/need-rating',
                            admin: true
                        }, {
                            id: 'Done',
                            title: 'Done',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/user/complete',
                            url: '/admin/user/complete',
                            admin: true
                        },
                        {
                            id: 'AdminFolder',
                            title: 'Admin Folder',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/user/admin-folder',
                            url: '/admin/user/admin-folder',
                            admin: true
                        },
                        {
                            id: 'NoRespond',
                            title: 'No Respond',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/user/no-respond',
                            url: '/admin/user/no-respond',
                            admin: true
                        },
                        {
                            id: 'ChallengeExpired',
                            title: 'Challenge Expired',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/user/coding-challenge-expired',
                            url: '/admin/user/coding-challenge-expired',
                            admin: true
                        }]
                    }, {
                        id: 'customer',
                        title: 'Customer',
                        subtitle: 'List of all companies',
                        type: 'group',
                        icon: 'heroicons_outline:document',
                        children: [{
                            id: 'Company',
                            title: 'Company',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/company/list',
                            url: '/admin/company/list',
                            admin: true
                        },
                        {
                            id: 'JobList',
                            title: 'Job List',
                            type: 'basic',
                            icon: 'heroicons_outline:folder-open',
                            link: '/admin/company/joblist',
                            url: '/admin/company/joblist',
                            admin: true
                        }]
                    }, {
                        id: 'codingchallenge',
                        title: 'Coding Challenge',
                        subtitle: 'List of all coding challenges',
                        type: 'group',
                        icon: 'heroicons_outline:document',
                        children: [
                            {
                                id: 'frontend',
                                title: 'Frontend',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/1',
                                url: '/admin/codingchallenges/1',
                                admin: true
                            },
                            {
                                id: 'backend',
                                title: 'Backend',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/2',
                                url: '/admin/codingchallenges/2',
                                admin: true
                            },
                            {
                                id: 'mobile',
                                title: 'Mobile',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/3',
                                url: '/admin/codingchallenges/3',
                                admin: true
                            },
                            {
                                id: 'blockchain',
                                title: 'Blockchain',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/4',
                                url: '/admin/codingchallenges/4',
                                admin: true
                            },
                            {
                                id: 'fullstack',
                                title: 'Fullstack',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/5',
                                url: '/admin/codingchallenges/5',
                                admin: true
                            },
                            {
                                id: 'mobile fullstack',
                                title: 'Mobile Fullstack',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/6',
                                url: '/admin/codingchallenges/6',
                                admin: true
                            },
                            {
                                id: 'data science',
                                title: 'Data Science',
                                type: 'basic',
                                icon: 'heroicons_outline:folder-open',
                                link: '/admin/codingchallenges/7',
                                url: '/admin/codingchallenges/7',
                                admin: true
                            }
                        ]
                    }]
            }
        });
        this.folderPipeSub = this._userService.folder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((f) => {
            this.myFolders = [];
            console.log('folders received: ', f)
            if (f) {
                // if (this.isAdmin == false) {
                f.map(async (folder) => {
                    this.myFolders.push({
                        id: folder['id'],
                        title: folder['name'],
                        type: 'basic',
                        icon: 'heroicons_outline:folder-open',
                        link: '/business/folder/' + folder['id'],
                        url: folder['url'],
                        message: folder['message'],
                        meta: 'folder',
                        admin: this.isAdmin
                    })
                });

                if (this.isAdmin === false) {
                    this.navigationData = [{
                        id: 'pages',
                        title: 'FOLDER',
                        subtitle: 'Categorise talents',
                        type: 'group',
                        icon: 'heroicons_outline:document',
                        children: this.myFolders
                    },
                    {
                        id: 'addnew',
                        title: 'Add new folder',
                        type: 'basic',
                        // link: '/profiles/stackoverflow'
                        function: this.navigationButtonClickFunction,
                        meta: 'button'
                    }];
                }
                else {
                    let index = this.navigationData.findIndex(x => x.id === 'pages');
                    if (index !== -1) {
                        this.navigationData[index] = {
                            id: 'pages',
                            title: 'FOLDER',
                            subtitle: 'Categorise talents',
                            type: 'group',
                            icon: 'heroicons_outline:document',
                            children: this.myFolders
                        };
                    }
                    else {
                        this.navigationData.push(
                            {
                                id: 'pages',
                                title: 'FOLDER',
                                subtitle: 'Categorise talents',
                                type: 'group',
                                icon: 'heroicons_outline:document',
                                children: this.myFolders
                            }
                        )
                    }
                    let index2 = this.navigationData.findIndex(x => x.id === 'addnew');
                    if (index2 !== -1) {
                        this.navigationData[index2] = {
                            id: 'addnew',
                            title: 'Add new folder',
                            type: 'basic',
                            // link: '/profiles/stackoverflow'
                            function: this.navigationButtonClickFunction,
                            meta: 'button'
                        };
                    }
                    else {
                        this.navigationData.push(
                            {
                                id: 'addnew',
                                title: 'Add new folder',
                                type: 'basic',
                                // link: '/profiles/stackoverflow'
                                function: this.navigationButtonClickFunction,
                                meta: 'button'
                            }
                        )
                    }
                }
                // }
            }
        });
        // this.getAllMyFolders();
        // Subscribe to the resolved route mock-api
        this.routeSub = this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;

        });

        // Subscribe to media changes
        this.mediaSub = this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        console.log('classy destroyed');
        this.folderSub.unsubscribe();
        this.businessDialogSub.unsubscribe();
        this.generalDialogSub.unsubscribe();
        this.userSub.unsubscribe();
        this.folderPipeSub.unsubscribe();
        this.routeSub.unsubscribe();
        this.mediaSub.unsubscribe();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
            if (name === 'mainNavigation') {
                this.navigationOpened = !this.navigationOpened;
            }
        }
    }

    updateCurrentUser() {
        console.log('updateCurrentUser is triggered');
        this._userService.getCurrentUserLite().subscribe(r => {
            console.log('current user lite: ', r);
            let name = r.businessName;
            if (!r.businessName) {
                name = r.firstName + ' ' + r.lastName;
            }
            this._userService.user = {
                id: r.id,
                avatar: r.photoUrl,
                email: r.email,
                name: name,
                role: r.role,
                status: r.status,
                plan: this._userService.user.plan
            }
        })
    }

    getAllMyFolders() {
        return new Promise<void>((resolve, reject) => {
            this._businessService.getAllMyFolders().subscribe((k) => {
                if (k != null) {
                    this._userService.folder = k;
                    this._userService.folder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((f) => {
                        this.myFolders = [];
                        console.log('folders received: ', f)
                        if (f) {
                            if (this.isAdmin == false) {
                                f.map(async (folder) => {
                                    this.myFolders.push({
                                        id: folder['id'],
                                        title: folder['name'],
                                        subject: folder['subject'],
                                        type: 'basic',
                                        icon: 'heroicons_outline:folder-open',
                                        link: '/business/folder/' + folder['id'],
                                        url: folder['url'],
                                        message: folder['message'],
                                        meta: 'folder',
                                        admin: this.isAdmin
                                    })
                                });

                                this.navigationData = [{
                                    id: 'pages',
                                    title: 'FOLDER',
                                    subtitle: 'Categorise talents',
                                    type: 'group',
                                    icon: 'heroicons_outline:document',
                                    children: this.myFolders
                                },
                                {
                                    id: 'addnew',
                                    title: 'Add new folder',
                                    type: 'basic',
                                    // link: '/profiles/stackoverflow'
                                    function: this.navigationButtonClickFunction,
                                    meta: 'button'
                                }];
                            }
                        }
                    });
                }
                else {
                    this._userService.folder = [];
                    this.myFolders = [];
                }
                resolve();
            }, err => {
                console.error('err: ', err);
                reject();
            });
        })
    }

    navigationButtonClickFunction() {
        console.log('button click is working', this._matDialog)
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(AddFolderComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",
                data: {
                    type: 1
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result.submit == true) {
                    }
                    console.log('add folder dialog was closed!');
                });
        }
    }

    async openInvite() {
        console.log('button click is working', this._matDialog);
        await this.getAllMyFolders();
        console.log('this.myFolders: ', this.myFolders);
        if (this.myFolders.length > 0) {
            if (!this.loading) {
                this.loading = true;
                const dialogRef = this._matDialog.open(InviteComponent, {
                    maxWidth: "90vw",
                    maxHeight: "90vh",
                    data: {
                        folder: this.myFolders,
                        currentFolderId: this.currentFolderId
                    }
                });

                dialogRef.afterClosed()
                    .subscribe(result => {
                        this.loading = false;
                        if (result && result.submit == true) {
                            this.getAllMyFolders();
                        }
                        else if (result && result.opencsv == true) {
                            this.openCsvInvite();
                        }
                        console.log('add folder dialog was closed!');
                    });
            }
        }
        else {
            this._matSnackBar.open('Please create a talent folder first.', 'Close');
            this.navigationButtonClickFunction();
        }
    }

    async copyInviteLink() {
        console.log('copyInviteLink is triggered');
        console.log('this.myFolders: ', this.myFolders);
        if (this.myFolders.length > 0 && this.currentFolderId) {
            const index = this.myFolders.findIndex(f => f.id === this.currentFolderId);
            if (index !== -1) {
                let val = environment.websiteUrl + "profile-link/company/" + this.myFolders[index].url;
                const selBox = document.createElement("textarea");
                selBox.style.position = "fixed";
                selBox.style.left = "0";
                selBox.style.top = "0";
                selBox.style.opacity = "0";
                selBox.value = val;
                document.body.appendChild(selBox);
                selBox.focus();
                selBox.select();
                document.execCommand("copy");
                document.body.removeChild(selBox);
                this._matSnackBar.open('Invitation url copied.', 'Close', {
                    duration: 4000
                });
            }
            else {
                this._matSnackBar.open('Please select a folder.', 'Close', {
                    duration: 4000
                });
            }
        }
        else {
            this._matSnackBar.open('Please select a folder.', 'Close', {
                duration: 4000
            });
        }
    }

    openCsvInvite() {
        console.log('button click is working', this._matDialog)
        console.log('this.myFolders: ', this.myFolders);
        if (!this.loading) {
            this.loading = true;
            const dialogRef = this._matDialog.open(InviteCsvuploadComponent, {
                maxWidth: "90vw",
                maxHeight: "90vh",
                disableClose: true,
                data: {
                    folder: this.myFolders,
                    currentFolderId: this.currentFolderId
                }
            });

            dialogRef.afterClosed()
                .subscribe(result => {
                    this.loading = false;
                    if (result && result.submit == true) {
                        this.getAllMyFolders();
                    }
                    else if (result && result.openinvite == true) {
                        this.openInvite();
                    }
                    console.log('add folder dialog was closed!');
                });
        }
    }

    openUploadImage() {
        this.addUserAction('business_side_nave_profilepic_click');
        this.profileuploader.nativeElement.click();
    }

    openFile(event) {

    }

    onInputImage(event) {
        this.addUserAction('business_side_nave_profilepic_file_click');
        console.log("onFileSelected is triggered");
        const files = event.dataTransfer
            ? event.dataTransfer.files
            : event.target
                ? event.target.files
                : event;
        console.log("event:", event);
        console.log("files: ", files);
        // console.log("this.files: ", this.files);
        const file = files[0];
        file.objectURL = this._domSanitizer.bypassSecurityTrustUrl(
            window.URL.createObjectURL(files[0])
        );
        console.log("files[0]: ", files[0]);

        let uploadFiles = new FormData();

        uploadFiles.append("photo", file);
        this._businessService
            .uploadPhotoV2(this.business.id, uploadFiles)
            .subscribe((updatedInfo) => {
                this.business['avatar'] = updatedInfo.photoUrl;
                this._userService.user = this.business;
            });

        event.target.value = "";
        this.clearInputElement();
    }

    clearInputElement() {
        console.log("clearInputElement is triggered");
        this.profileuploader.nativeElement.value = "";

    }
    /**
    * Sign out
    */
    signOut(): void {
        console.log('signOut is triggered');
        this._matSnackBar.open('Token Expired.', 'Close');
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._router.navigate(['/sign-out']);
    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.business.id,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }
}
