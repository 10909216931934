<div class="flex flex-col w-160 -m-6 bg-gray-50">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 mt-5 ">
        <div class="text-3xl font-medium">Edit business name</div>
        <button mat-icon-button class="bg-gray-300" (click)="saveAndClose()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <form class="flex flex-col flex-auto  p-6 sm:p-8 overflow-y-auto" [formGroup]="composeForm" autocomplete="off">

        <mat-form-field class="fuse-mat-textarea  w-full">

            <mat-icon class="icon-size-5" matPrefix svgIcon="mat_outline:work_outline"></mat-icon>
            <mat-label>Business name</mat-label>
            <input placeholder="Business Name" matInput [formControlName]="'name'">
            <mat-hint>Enter your business name.</mat-hint>
            <mat-error *ngIf="composeForm.get('name').hasError('maxlength')">
                Your business name cannot exceed 50 characters.
            </mat-error>
            <mat-error *ngIf="composeForm.get('name').hasError('required')">
                Please enter a business name.
            </mat-error>
        </mat-form-field>

        <!-- Actions -->
        <div class="flex flex-row sm:items-center justify-between mt-4 sm:mt-6">


            <div class="flex items-center mt-4 sm:mt-0 ml-auto">
                <!-- Send -->
                <button class="mr-4" mat-flat-button (click)="saveAndClose()">
                    Cancel
                </button>
                <button class="order-first sm:order-last" mat-flat-button [color]="'primary'"
                    [disabled]="!composeForm.valid || loader" (click)="saveChanges()">
                    Save
                    <mat-progress-spinner *ngIf="loader" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </form>
</div>