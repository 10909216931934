import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessService } from 'app/core/services/business.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'invite-talent',
    templateUrl: './invite.component.html',
    encapsulation: ViewEncapsulation.None
})
export class InviteComponent implements OnInit {
    composeForm: FormGroup;

    currentFolderId: number;

    folders: any = [];

    errorCode: number = 0;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    foldUrl: string;
    loader: boolean = false;
    mobileDevice: any = 'Desktop';
    userActionLoading: any = false;
    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<InviteComponent>,
        private _formBuilder: FormBuilder,
        private _businessService: BusinessService,
        private _matSnackBar: MatSnackBar,
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile();
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });
        // Create the form

        console.log('this.data', this.data)
        this.folders = this.data.folder;
        let folder = this.data.folder.find(f => f.id === this.data.currentFolderId);
        if (folder) {
            this.foldUrl = environment.websiteUrl + "profile-link/company/" + folder.url;
        }
        this.composeForm = this._formBuilder.group({
            folder: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            fname: ['', [Validators.required]],
            lname: ['', [Validators.required]],
            subject: ['', [Validators.required]],
            message: ['', [Validators.required]],
            is_html: [false]
        });

        if (this.data.currentFolderId && this.folders.findIndex(f => f.id === this.data.currentFolderId) !== -1) {
            this.currentFolderId = this.data.currentFolderId;
            let message = this.folders.find(f => f.id === this.currentFolderId).message;
            let subject = this.folders.find(f => f.id === this.currentFolderId).subject;
            if (!message || message === '') {
                let folder = this.data.folder.find(f => f.id === this.data.currentFolderId);
                this.foldUrl = environment.websiteUrl + "profile-link/company/" + folder.url;
                message =
                    `Hi FIRST_NAME,

Thank you for your interest in this position. Congratulations, you have been shortlisted and we would like to invite you to link your external accounts (Github, Stack Overflow, Linkedin) as part of our recruitment process.

The link for proceeding to the next step is at the end of this email. It was designed to evaluate your skills and help us to understand your approach in situations relevant to this position.

Please complete this as soon as possible to ensure you are part of the process.

Congratulations again on this exciting opportunity and the best of luck.

Please feel free to contact us if you have any questions.

The link is attached below. Please make sure to click it to proceed. 

Sincerely,
                    
`
            }
            this.composeForm.patchValue({
                folder: this.data.currentFolderId,
                message: message,
                subject: subject
            })
            this._changeDetectorRef.markForCheck();
        }

        this.composeForm.valueChanges.subscribe(r => {
            console.log('r: ', r);
            if (r.email && r.email !== this.excludeSpecial(r.email)) {
                const modifiedEmail = this.excludeSpecial(r.email);
                if (modifiedEmail !== r.email) {
                    this.composeForm.patchValue({
                        email: modifiedEmail
                    })
                }
            }
            if (r.folder !== this.currentFolderId) {
                this.currentFolderId = r.folder;
                let message = this.folders.find(f => f.id === this.currentFolderId).message;
                let subject = this.folders.find(f => f.id === this.currentFolderId).subject;
                if (!message || message === '') {
                    let folder = this.data.folder.find(f => f.id === this.composeForm.value.folder);
                    this.foldUrl = environment.websiteUrl + "profile-link/company/" + folder.url;
                    message =
                        `Hi FIRST_NAME,

Thank you for your interest in this position. Congratulations, you have been shortlisted and we would like to invite you to link your external accounts (Github, Stack Overflow, Linkedin) as part of our recruitment process.

The link for proceeding to the next step is at the end of this email. It was designed to evaluate your skills and help us to understand your approach in situations relevant to this position.

Please complete this as soon as possible to ensure you are part of the process.

Congratulations again on this exciting opportunity and the best of luck.

Please feel free to contact us if you have any questions.

The link is attached below. Please make sure to click it to proceed. 

Sincerely,
                    
`
                }
                this.composeForm.patchValue({
                    message: message,
                    subject: subject
                })
                this._changeDetectorRef.markForCheck();
            }
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Save and close
     */
    changeIsHtml(): void {
        console.log('changeIsHtml is triggered');
        console.log('this.composeForm.value.is_html: ', this.composeForm.value.is_html);
    }

    /**
     * Save and close
     */
    saveAndClose(): void {
        this.addUserAction('business_invite_popup_close_click');
        // Save the message as a draft
        this.saveAsDraft();

        // Close the dialog
        this.matDialogRef.close();
    }

    /**
     * Discard the message
     */
    discard(): void {

    }

    /**
     * Remove special character
     */
    excludeSpecial(str) {
        str = str.replace(/\\f/g, '').replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\t/g, '').replace(/[\'\"\\\/\b]/g, '').replace(/(\r\n|\n|\r)/gm, "");;
        return str;
    }

    /**
     * Check the message
     */
    validate() {
        console.log('validate is triggered');
        const message = this.composeForm.value.message;
        this._changeDetectorRef.markForCheck();
        // return this.composeForm.valid;
        return new Promise(resolve => {
            // if (message) {
            //     // if (message.includes("POSITION_NAME")) {
            //     //     this.errorCode = 1;
            //     //     this._changeDetectorRef.markForCheck();
            //     //     resolve(false);
            //     // }
            //     // else
            //     // if (message.includes("COMPANY_NAME")) {
            //     //     this.errorCode = 2;
            //     //     this._changeDetectorRef.markForCheck();
            //     //     resolve(false);
            //     // }
            //     // else
            //     // if (message.includes("DEADLINE")) {
            //     //     this.errorCode = 3;
            //     //     this._changeDetectorRef.markForCheck();
            //     //     resolve(false);
            //     // }
            //     // else
            //     // console.log('this.foldUrl: ', this.foldUrl);
            //     // console.log('message.includes(this.foldUrl): ', message.includes(this.foldUrl));
            //     // if (!(message.includes(this.foldUrl))) {
            //     //     this.errorCode = 4;
            //     //     this._changeDetectorRef.markForCheck();
            //     //     resolve(false);
            //     // }
            //     // else {
            //     //     this.errorCode = 0;
            //     // }
            //     this.errorCode = 0;
            // }
            // else {
            //     this.errorCode = 0;
            // }
            this._changeDetectorRef.markForCheck();
            resolve(this.composeForm.valid);
        })
    }

    /**
     * Save the message as a draft
     */
    saveAsDraft(): void {

    }

    /**
     * Send the message
     */
    async invite(): Promise<void> {
        this.addUserAction('business_invite_popup_invite_click');
        const v = await this.validate();
        this._changeDetectorRef.markForCheck();
        if (v) {
            if (!this.loader) {
                this.loader = true;
                this._businessService.createInvitation(
                    this.composeForm.value.folder,
                    this.composeForm.value.email,
                    this.composeForm.value.fname,
                    this.composeForm.value.lname,
                    this.composeForm.value.subject,
                    this.composeForm.value.message,
                    this.user.email
                ).subscribe((k) => {
                    console.log('invite', k)
                    this._matSnackBar.open('Invitation sent.', 'Close', {
                        duration: 4000
                    });
                    this._businessService.emitChange({
                        folderId: this.composeForm.value.folder,
                        type: 'invitation'
                    });
                    this.matDialogRef.close(true);
                },
                    err => {
                        if (err.status === 404) {
                            this._matSnackBar.open('Folder deleted, please choose another folder. ', 'Close');
                            if (Array.isArray(this.folders)) {
                                let index = this.folders.findIndex(f => f.id === this.composeForm.value.folder)
                                if (index > -1) {
                                    this.folders.splice(index, 1);
                                }
                            }
                            this._changeDetectorRef.markForCheck();
                        }
                        else {
                            this._matSnackBar.open('An error occurs, please try again later. ', 'Close');
                        }
                        this.loader = false;
                    })
            }
        }
        else {
            console.log('this.errorCode: ', this.errorCode);
            this._matSnackBar.open('Please check your invitation content before sending. ', 'Close');
        }
    }

    /**
     * Send the message
     */
    async sendTestInvitationEmail() {
        this._changeDetectorRef.markForCheck();
        console.log('start sending test email')
        if (!this.loader) {
            this.addUserAction('business_invite_popup_send_test_mail_click');
            this.loader = true;

            console.log('this.composeForm.value.folder: ', this.composeForm.value.folder);
            if (this.composeForm.value.folder != '' && this.composeForm.value.folder != null) {
                const folder = this.data.folder.find(f => f.id === this.composeForm.value.folder);
                let url = environment.websiteUrl + "/profile-link/company/" + folder.url
                let email = JSON.parse(localStorage.getItem('tri-user'))['email'];
                if (url && email) {
                    this._businessService.sendTestInvitationEmail(email, this.composeForm.value.folder, url, this.composeForm.value.subject, this.composeForm.value.message).subscribe(() => {
                        this._matSnackBar.open('Invitation email sent.', 'Close', {
                            duration: 4000
                        });
                        this.loader = false;
                    })
                }
                else {
                    this._matSnackBar.open('Session expired. Please log in again.', 'Close', {
                        duration: 4000
                    });
                    this.loader = false;
                }
            }
            else {
                this._matSnackBar.open('Please select a folder.', 'Close', {
                    duration: 4000
                });
                this.loader = false;
            }
        }
    }

    /**
     * copy content
     */
    copyMessage(val: string) {
        this.addUserAction('business_invite_popup_message_copy_click');
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this._matSnackBar.open('Message copied', 'Close', {
            duration: 4000
        })
    }


    /**
     * copy url
     */
    copyUrl() {
        console.log('copyUrl is triggered');
        this.addUserAction('business_invite_popup_url_copy_click');
        console.log('this.composeForm.value.folder: ', this.composeForm.value.folder);
        if (this.composeForm.value.folder != '' && this.composeForm.value.folder != null) {
            const folder = this.data.folder.find(f => f.id === this.composeForm.value.folder);
            let val = environment.websiteUrl + "profile-link/company/" + folder.url
            const selBox = document.createElement("textarea");
            selBox.style.position = "fixed";
            selBox.style.left = "0";
            selBox.style.top = "0";
            selBox.style.opacity = "0";
            selBox.value = val;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand("copy");
            document.body.removeChild(selBox);
            this._matSnackBar.open('Invitation url copied.', 'Close', {
                duration: 4000
            });
        }
        else {
            this._matSnackBar.open('Please select a folder.', 'Close', {
                duration: 4000
            });
        }
    }

    openCsvPopup() {
        this.addUserAction('business_invite_popup_csv_input_click');
        this.matDialogRef.close({
            opencsv: true
        });
    }

    isMobile() {
        let info = navigator.userAgent;
        let agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPod",
            "iPad",
        ];
        console.log("info: ", info);
        for (let i = 0; i < agents.length; i++) {
            if (info.indexOf(agents[i]) >= 0) {
                this.mobileDevice = agents[i];
                return true;
            }
        }
        return false;
    }

    addUserAction(action) {
        if (this.userActionLoading == false) {
            this.userActionLoading = true;
            let actionData = {
                'userId': this.user.id,
                'action': action,
                'device': this.mobileDevice
            }
            console.log('user_action', actionData)
            this._businessService.addUserAction(actionData).subscribe(r => {
                console.log('user_action stored', r)
                this.userActionLoading = false;
            }, (err) => {
                console.log('user_action failed', err)
                this.userActionLoading = false;
            });
        }
    }
}
